<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">Add a solution</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper p-5" [hidden]="commonService.showSkeletonLoader">
  <form [formGroup]="myForm" novalidate>
    <div class="container">
      <div id="collapseBasic" [collapse]="isCollapsed">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="sr-only" for="addtitle">Add Title</label>
              <input
                type="text"
                class="form-control"
                id="addtitle"
                name="title"
                placeholder="Add Title"
                formControlName="title"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="sr-only" for="reason">Example textarea</label>
              <textarea
                class="form-control txta"
                id="reason"
                name="description"
                placeholder="Reason for presentation to medical care"
                rows="5"
                formControlName="description"
              ></textarea>
              <span
                class="error-msg"
                *ngIf="
                  (submitted || myForm.controls.description.touched) &&
                  myForm.controls.description.invalid
                "
              >
                {{
                  showErrorService.showError(
                    'incidentReason',
                    myForm.controls.description.errors
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Tag for Incident -->
        <div class="col-12">
          <p class="mb-4">Tag for Incident</p>
        </div>
        <div class="col-12">
          <div class="form-group">
            <div class="form-group">
              <label class="sr-only" for="tagfor">Tag for Incident</label>
              <ng-select
                class="custom-slectmenu with-tag"
                [items]="tagListArray"
                bindLabel="name"
                bindValue="_id"
                placeholder="Tag for Incident"
                name="tags"
                [multiple]="true"
                formControlName="tags"
              >
              </ng-select>
              <div *ngIf="f.tags.touched && f.tags.invalid" class="error-msg">
                <div *ngIf="f.tags.errors.required">Tag required.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="upload-img-list d-flex flex-wrap flex-md-row mt-2">
            <div class="form-group custom-upload-list mr-3 mb-3 mb-0">
              <label class="sr-only" for="imageuploadlist">
                file or Drag & drop image here.t</label
              >
              <input
                type="file"
                class="form-control-file"
                id="imageuploadlist"
                name="media"
                formControlName="media"
                (change)="onFileSelected($event, 'solution')"
              />
              <span class="bx bx-plus icon-pink-color"></span>
            </div>
            <!-- Uploade field -->
            <div class="upload-img-list d-flex flex-wrap flex-md-row mt-2">
              <ng-container *ngIf="solutionImageAndVideos.length">
                <div
                  class="uploaded-img mr-3 mb-3"
                  *ngFor="let data of solutionImageAndVideos; let ind = index"
                >
                  <span
                    class="delete-item icon-close"
                    (click)="removeData(data, ind, 'solution')"
                  ></span>

                  <picture>
                    <img
                      width="76"
                      height="76"
                      [src]="data.path"
                      alt="upload Image"
                      #img
                      *ngIf="data.type == 'image'"
                    />
                  </picture>
                  <picture>
                    <img
                      width="76"
                      height="76"
                      [src]="data.path"
                      alt="upload Image"
                      #img
                      *ngIf="data.type == 'video'"
                    />
                  </picture>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div>
        <button
          type="submit"
          class="btn btn-primary mr-2"
          [disabled]="!myForm.valid"
          (click)="saveProduct()"
        >
          {{ this.solutionsId ? 'Update' : 'Add' }}
        </button>
      </div>
    </div>
  </form>
</div>
