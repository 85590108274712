export const URLConstants = {
  /***** Unauthorized Pages *****/
  LOGIN: '/login',
  FORGOT_PASS: '/forgot-password',
  RESET_PASS: '/reset-password',
  SIGNUP: '/register',
  DASHBOARD: '/Dashboards',
  OTP: '/enter-otp/',

  /***** Authorized Pages *****/
  CHANGE_PASS: '/change-password',
  MY_PROFILE: '/my-profile',
  USERS: '/users/list',
  AVIATIONUSERS: '/users/aviation',
  ADDUSERS: '/users/add/',
  INVITEUSERS: '/users/invite',
  EDITUSERS: '/users/edit/',
  ADMINUSERS: '/admin-users',
  ADDADMINUSER: '/admin-users/add-user',
  EDITADMINUSER: '/admin-users/edit-details/',
  MASTER: '/master',
  /***** Setting Pages *****/
  SETTING_GENERAL: '/setting/setting-general',
  SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
  SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
  SETTING_SMTPSMS: '/setting/setting-smtpsms',
  SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',

  // Email Templates
  EMAIL_TEMPLATE_list: '/email-template',
  EMAIL_TEMPLATE_detail: '/email-template/edit-details/',
  EMAIL_TEMPLATE_ADDNEWTEMPLATE: '/email-template/addNewTemplate',

  // content Management
  CONTENT: '/content',
  STATIC_PAGE: '/content/Static-Page',
  ADD_STATIC_PAGE: '/content/Add-static-page',
  EDIT_STATIC_PAGE: '/content/edit-static-page/',
  VIEW_STATIC_DETAILS: '/content/view-static-page-details',

  BLOGS_CATEGORY: '/content/blog-category',
  BLOGS: '/content/blog-list',
  BLOG_ADD: '/content/blog-add',
  BLOG_EDIT: '/content/blog-edit/',
  BLOG_PREVIEW: '/content/view-blog-details/',

  FAQS: '/content/faq',

  // user list
  USERS_DETAIL: '/users/view-details/',
  ADDUPDATEUSER: '/users/add-update-user/',

  //manage roles
  MANAGE_ROLES: '/manage-roles',
  ADD_ROLE: '/manage-roles/add-role',
  EDIT_ROLE: '/manage-roles/edit-details/',
  //Tags
  TAG: '/tag',
  NEWS: 'news',
  BLOGSMNG: 'blogs',
  REGION: 'region',
  QUESTION: 'question-management/list',
  FRQUESTION: 'question-management/french-list',
  DEQUESTION: 'question-management/german-list',
  DESIGNTION: 'designations',
  POSITION: 'positions',
  HEALTHCAREROLE: 'healthcareroles',
  REPORT: 'report',
  SIDEBARREPORT: 'report/list',
  SOLUTION_REPORT: 'report/solution',
  SUB_REGION: 'sub-region',
  DOMAIN_COMPANIES: 'domain-companies',
  INCIDENTS: 'incident-management',
  SIDEBARINCIDENTS: 'incident-management/list',
  SIDEBARAVIATIONINCIDENTS: 'incident-management/aviation',
  INCIDENTS_DELETE: 'incident-management/list-delete',
  INCIDENTS_TRANSLATION: 'incident-management/list-translation',
  MANAGE_QUESTIONNAIRE: 'question-management/manage-questionnaire/',
  SOLUTION_TRANSLATION: 'solution-management/list-translation',
  NEWS_TRANSLATION: 'news/list-translation',
  TAGS_TRANSLATION: 'tag/list-translation',
  INFOGRAPHIC_TRANSLATION: '/post-management/infographic-translation',
  SIDEBARREPORTS: 'report-management/list',
  COMPLETEDREPORTS: 'report-management/completedlist',
  ACTIONITEMS: 'report-management/actionitems',
  CHEOREVIEWREPORTS: '/report-management/review-incident',
  SOLUTIONS: 'solution-management',
  SOLUTIONS_DELETE: 'solution-management/list-delete',
  EDITINCIDENT: '/incident-management/edit/',
  ADDINCIDENTS: '/incident-management/add',
  ADDINCIDENTSAVIATION: '/incident-management/add-aviation',
  EDITINCIDENTAVIATION: '/incident-management/edit-aviation/',
  ADDSOLUTION: '/solution-management/add',
  EDITCHEOREPORTS: '/report-management/edit',
  DEPARTMENTS: 'departments',
  EXPERTISE: 'expertise',
  TRAINING: 'training',
  MECHANICALFAILURE: 'mechanicalFailure',
  AIRLINE: 'airline',
  SERVICE: 'place-of-service',
  ADDCONTENT: '/content/add',
  CMS_DETAIL: '/content/edit',
  RECOMMANDED_DOMAIN: 'domain-recommendation',
  REACH_US: 'reachus', //admin​/dashboardMetaData
  POSTS: 'post-management/post-list',
  EDITPOST: '/post-management/post-list/edit/',
  ADDPOST: '/post-management/post-list/add',
  INFOGRAPHICS: 'post-management/infographic-list',
  ADDGRAPHIC: '/post-management/infographic-list/add',
  EDITGRAPHIC: '/post-management/infographic-list/edit/',
  EMAILS: '/pre-approved-emails/emails-list',
  EMAILTAGS: '/pre-approved-emails/email-tags',
  ADDEMAILSCSV: '/pre-approved-emails/add-emails-csv',
  ADDTAGCSV: '/tag/add-tag-csv',
  COMMENTS: '/comment-management',
  QRCAMPAIGNS: '/qr-management/list',
  VIEWQRCAMPAIGN: '/qr-management/view',
  REPLIES: '/reply-management',
  VIEWPOSTSOLUTIONS: '/post-management/post-list/post-solutions/',
  VIEWPOSTCOMMENTS: '/post-management/post-list/post-comments/',
  ADDPOSTSOLUTIONS: '/post-management/post-list/post-solutions/add',
  POST_TRANSLATION: '/post-management/list-translation',
  BLOGS_TRANSLATION: 'blogs/list-translation',
};
