import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import * as _ from 'lodash';
import { AngularEditorConfig, UploadResponse } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-blogs.component.html',
  styleUrls: ['./add-blogs.component.scss'],
})
export class AddBlogsComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  BlogsData: any = {};
  buttonName: any = 'Add';
  typeList = [
    { name: 'Podcast', value: 'podcast' },
    { name: 'Article', value: 'article' },
  ];
  public filesize: any;
  public userIncidentFileData: any = {};
  public userPhotoAndVideoData = '';
  public userIncidentFilePath = '';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    uploadUrl: 'v1/image',
    //upload: (file: File) => { ... }
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData?.type === 'edit_blogslist') {
        this.buttonName = 'Update';

        this.BlogsData.blogsId = this.bsModalRef?.content?.modalData?.blogsId;
        this.BlogsData.title = this.bsModalRef?.content?.modalData?.title;
        this.BlogsData.author = this.bsModalRef?.content?.modalData?.author;
        this.BlogsData.type = this.bsModalRef?.content?.modalData?.linkType;
        this.BlogsData.description =
          this.bsModalRef?.content?.modalData?.description;
        this.BlogsData.blogTemplate =
          this.bsModalRef?.content?.modalData?.blogTemplate;
        this.BlogsData.link = this.bsModalRef?.content?.modalData?.link;
        this.BlogsData.isActive = this.bsModalRef?.content?.modalData?.isActive;
        if (this.bsModalRef?.content?.modalData?.thumbnail) {
          this.userPhotoAndVideoData =
            this.bsModalRef?.content?.modalData?.thumbnail;

          var splitFilePath =
            _.toString(
              _.split(
                this.bsModalRef?.content?.modalData?.thumbnail,
                'images/'
              )[1]
            ) || 'images/';

          this.userIncidentFilePath = splitFilePath;
        }
      }
    });
  }
  /****************************************************************************
  @Purpose     : Add/Update department
  @Parameter   : form, department
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.BlogsData.blogsId) {
      let dataObj = {
        blogsId: this.BlogsData.blogsId,
        title: form.value.title,
        author: form.value.author,
        description: form.value.description,
        link: form.value.link,
        isActive: form.value.isActive,
        type: form.value.type,
        blogTemplate: form.value.blogTemplate,
        thumbnail: this.userIncidentFilePath,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditBlogs + '/' + dataObj.blogsId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.router.navigate([this.URLConstants.BLOGSMNG]);

              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.BLOGSMNG]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        const objdata = {
          ...form.value,
          thumbnail: this.userIncidentFilePath,
        };
        this.commonService
          .callApi(this.callAPIConstants.AddBlogs, objdata, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.BLOGSMNG]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }

  /****************************************************************************
     @Purpose     : OnFileUpload
     @Parameter   : Event,typeFile
     @Return      : Na
  /****************************************************************************/
  onFileSelected(event, typeFile) {
    if (event.target.files.length) {
      var input = event.target;
      var allowedExtensions =
        /(\.jpg|\.jpeg|\.png)|\.mp4|\.avi|.mov|.mpg|.3gp|.asf$/i;
      this.filesize = event.target.files[0].size / 1024 / 1024 + 'MB';
      if (allowedExtensions.exec(event.target.value)) {
        var input = event.target;
        var reader = new FileReader();

        reader.onload = (e: any) => {
          this.userIncidentFileData.uploadedPhoto = input.files[0];
          var fd = new FormData();

          this.userIncidentFileData.Type = 'image';
          event.srcElement.value = '';

          fd.append('moduleName', typeFile);
          fd.append('file', this.userIncidentFileData.uploadedPhoto);
          // this.spinnerService.show();
          this.commonService
            .callApi(
              this.callAPIConstants.BlogsFilesUpload,
              fd,
              'post',
              false,
              true
            )
            .then((success) => {
              // this.spinnerService.hide();
              if (success.status === 1) {
                if (success.data.filePath.includes('images/')) {
                  var splitFilePath =
                    _.toString(_.split(success.data.filePath, 'images/')[1]) ||
                    'images/';
                }
                var filePath = splitFilePath;

                let data = success.data.filePath;

                this.userPhotoAndVideoData = data;
                this.userIncidentFilePath = filePath;
              } else {
                this.showErrorService.popToast('error', success.message);
              }
            });
        };
        reader.readAsDataURL(input.files[0]);
        return true;
      } else if (!allowedExtensions.exec(event.target.value)) {
        this.showErrorService.popToast(
          'error',
          'Please select jpeg, jpg or png file.'
        );
        return false;
      }

      return true;
    }
  }

  /****************************************************************************
     @Purpose     : RemoveFile
     @Parameter   : data,index
     @Return      : Na
  /****************************************************************************/
  removeData(data, type) {
    this.userPhotoAndVideoData = '';
    this.userIncidentFilePath = '';

    let FileData = {
      filePath: data,
    };
    this.commonService
      .callApi(
        this.callAPIConstants.DeleteFileUpload,
        FileData,
        'post',
        false,
        false
      )
      .then((success) => {});
  }
}
