import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { ListComponent } from 'src/app/modules/content-management/list/list.component';
import { ListTranslationComponent } from 'src/app/modules/incident-management/list-translation/list-translation.component';

@Component({
  selector: 'app-add-infographic-translation',
  templateUrl: './add-infographic-translation.component.html',
  styleUrls: ['./add-infographic-translation.component.scss'],
})
export class AddInfographicTranslationComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  APIparameters: any = {};
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  TranslationData: any = {};
  buttonName: any = 'Add';
  infographicId: String;
  shortCodeList = [
    { name: 'en', value: 'en' },
    { name: 'de', value: 'de' },
    { name: 'fr', value: 'fr' },
  ];
  languageList = [
    { name: 'English', value: 'English' },
    { name: 'German', value: 'German' },
    { name: 'French', value: 'French' },
  ];

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {
    this.infographicId = this.localStorageService.getToken(
      'selectedInfographicId'
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (
        this.bsModalRef?.content?.modalData?.type ===
        'edit_infographictranslationlist'
      ) {
        this.buttonName = 'Update';

        this.TranslationData.translationId =
          this.bsModalRef?.content?.modalData?.translationId;
        this.TranslationData.mainTitle =
          this.bsModalRef?.content?.modalData?.mainTitle;
        this.TranslationData.description =
          this.bsModalRef?.content?.modalData?.description;
        this.TranslationData.language =
          this.bsModalRef?.content?.modalData?.language;
        this.TranslationData.shortCode =
          this.bsModalRef?.content?.modalData?.shortCode;
        this.TranslationData.isActive =
          this.bsModalRef?.content?.modalData?.isActive;
      }
    });
  }
  /****************************************************************************
  @Purpose     : Add/Update department
  @Parameter   : form, department
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.TranslationData.translationId) {
      let dataObj = {
        translationId: this.TranslationData.translationId,
        mainTitle: form.value.mainTitle,
        description: form.value.description,
        language: form.value.language,
        shortCode: form.value.shortCode,
        isActive: form.value.isActive,
      };
      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditTranslation + '/' + dataObj.translationId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.INFOGRAPHIC_TRANSLATION]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        const infographicId = this.infographicId;
        form.value.infographicId = infographicId;
        this.commonService
          .callApi(
            this.callAPIConstants.AddInfographicTranslation,
            form.value,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.INFOGRAPHIC_TRANSLATION]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
