import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import * as _ from 'lodash';
import { AngularEditorConfig, UploadResponse } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-blogs-translation.component.html',
  styleUrls: ['./add-blogs-translation.component.scss'],
})
export class AddBlogsTranslationComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  TranslationData: any = {};
  buttonName: any = 'Add';
  blogId: String;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  shortCodeList = [
    { name: 'en', value: 'en' },
    { name: 'de', value: 'de' },
    { name: 'fr', value: 'fr' },
  ];
  languageList = [
    { name: 'English', value: 'English' },
    { name: 'German', value: 'German' },
    { name: 'French', value: 'French' },
  ];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    uploadUrl: 'v1/image',
    //upload: (file: File) => { ... }
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {
    this.blogId = this.localStorageService.getToken('selectedBlogId');
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (
        this.bsModalRef?.content?.modalData?.type === 'edit_blogtranslationlist'
      ) {
        this.buttonName = 'Update';

        this.TranslationData.translationId =
          this.bsModalRef?.content?.modalData?.translationId;
        this.TranslationData.mainTitle =
          this.bsModalRef?.content?.modalData?.mainTitle;
        this.TranslationData.description =
          this.bsModalRef?.content?.modalData?.description;
        this.TranslationData.language =
          this.bsModalRef?.content?.modalData?.language;
        this.TranslationData.shortCode =
          this.bsModalRef?.content?.modalData?.shortCode;
        this.TranslationData.isActive =
          this.bsModalRef?.content?.modalData?.isActive;
        this.TranslationData.blogBody =
          this.bsModalRef?.content?.modalData?.blogBody;
      }
    });
  }
  createUpdate(form, Data) {
    if (this.TranslationData.translationId) {
      let dataObj = {
        translationId: this.TranslationData.translationId,
        mainTitle: form.value.mainTitle,
        description: form.value.description,
        language: form.value.language,
        shortCode: form.value.shortCode,
        isActive: form.value.isActive,
        blogBody: form.value.blogBody,
      };
      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditTranslation + '/' + dataObj.translationId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.BLOGS_TRANSLATION]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        const blogId = this.blogId;
        form.value.blogId = blogId;
        this.commonService
          .callApi(this.callAPIConstants.AddBlogTranslation, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.BLOGS_TRANSLATION]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
