<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{
        bsModalRef?.content?.modalData?.mainTitle
          ? 'Edit Translation'
          : 'Add Translation'
      }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, TranslationData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label>Title<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Title "
              name="mainTitle"
              id="name"
              #mainTitle="ngModel"
              [(ngModel)]="TranslationData.mainTitle"
              required
            />
            <div
              class="error-block text-left"
              *ngIf="(submitted || mainTitle.touched) && mainTitle.invalid"
            >
              Enter Titile
            </div>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              class="form-control"
              id="description"
              placeholder="Enter Description"
              rows="5"
              name="description"
              #description="ngModel"
              [(ngModel)]="TranslationData.description"
            ></textarea>
          </div>
          <div class="form-group">
            <label>Language<sup class="bx bxs-star danger"></sup></label>
            <ng-select
              class="text-input"
              name="language"
              [items]="languageList"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #language="ngModel"
              [(ngModel)]="TranslationData.language"
              [searchable]="true"
              autocomplete="language"
              placeholder="Enter Language "
              required
              disabled="{{
                bsModalRef?.content?.modalData?.mainTitle ? true : false
              }}"
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || language.touched) && language.invalid"
            >
              Enter language
            </div>
          </div>

          <div class="form-group">
            <label>Short code<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              disabled="{{
                bsModalRef?.content?.modalData?.mainTitle ? true : false
              }}"
              class="text-input"
              name="shortCode"
              [items]="shortCodeList"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #shortCode="ngModel"
              [(ngModel)]="TranslationData.shortCode"
              [searchable]="true"
              autocomplete="shortcode"
              placeholder="Enter shortcode "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || shortCode.touched) && shortCode.invalid"
            >
              Enter Short code
            </div>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="TranslationData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
