<div class="main-content-area">
  <div class="main-content-block">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="content-header-title">Master</li>
        <li class="breadcrumb-item">
          <a href="dashboard.html"><em class="bx bx-home-alt"></em></a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Manage Master
        </li>
      </ol>
    </nav>

    <div>
      <div
        class="animated fadeInUp box-wrapper notification-card card tabbing p-0"
      >
        <div class="m-portlet settings-wrapper approve-template custom-tabs">
          <tabset class="tab-links tab-content" id="content">
            <tab id="tab1" (selectTab)="onSelect('country')">
              <ng-template tabHeading>
                <i class="bx bx-globe align-middle mr-1"></i
                ><span class="align-middle">Country</span>
              </ng-template>
              <app-responsive-data-table
                [tableSetupData]="tableSetupData"
                [accessPermission]="accessPermission"
                *ngIf="tabs.country"
              >
              </app-responsive-data-table>
            </tab>

            <tab
              [active]="tabs.timezone"
              (selectTab)="onSelect('timezone')"
              customClass="date-icon common-icon"
            >
              <ng-template tabHeading (selectTab)="onSelect('timezone')">
                <i class="bx bx-time-five align-middle mr-1"></i
                ><span class="align-middle">Timezone</span>
              </ng-template>
              <app-responsive-data-table
                [tableSetupData]="tableSetupData"
                [accessPermission]="accessPermission"
                *ngIf="tabs.timezone"
              >
              </app-responsive-data-table>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
