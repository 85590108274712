import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  switchMap,
} from 'rxjs/operators';
// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

// Services
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';

@Component({
  selector: 'app-filter-type-ahead',
  templateUrl: './filter-type-ahead.component.html',
  styleUrls: ['./filter-type-ahead.component.scss'],
})
export class FilterTypeAheadComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  @Input() data: any;
  @Input() type: any;
  @Output() selectedValueOfFilter = new EventEmitter<any>();
  getColumn: any;
  public getMethodName: any;
  public filterlist: Observable<any>;
  public typeAheadSource = new Subject<string>();

  constructor(
    private commonService: CommonService,
    public restrictKeyPressService: RestrictKeyPressService
  ) {}

  ngOnInit() {
    if (this.type === 'userlist') {
      this.getMethodName = this.callAPIConstants.UserGetColumnData;
    } else if (this.type === 'adminUserlist') {
      this.getMethodName = this.callAPIConstants.AdminGetColumnData;
    } else if (this.type === 'roleList') {
      this.getMethodName = this.callAPIConstants.RoleGetColumnData;
    } else if (this.type === 'incidentlist') {
      if (this.data.colName === 'REGION') {
        this.getMethodName = this.callAPIConstants.GetColumnIncidentRegion;
      } else if (this.data.colName === 'DEPARTMENT') {
        this.getMethodName = this.callAPIConstants.GetColumnIncidentDepartment;
      } else if (this.data.colName === 'USER') {
        this.getMethodName = this.callAPIConstants.GetColumnIncidentAuthor;
      } else {
        this.getMethodName = this.callAPIConstants.GetColumnIncidentTitle;
      }
    } else if (this.type === 'domainlist') {
      if (this.data.colName === 'Region') {
        this.getMethodName = this.callAPIConstants.GetDomainColumnRegion;
      } else {
        this.getMethodName = this.callAPIConstants.GetDomainColumnSubRegion;
      }
    } else if (this.type === 'solutionlist') {
      if (this.data.colName === 'Solution') {
        this.getMethodName = this.callAPIConstants.GetColumnSolutionTitle;
      } else if (this.data.colName === 'Author') {
        this.getMethodName = this.callAPIConstants.GetColumnSolutionAuthor;
      } else {
        this.getMethodName = this.callAPIConstants.GetColumnSolutionParent;
      }
    } else if (this.type === 'reportsolutionlist') {
      if (this.data.colName === 'Solution') {
        this.getMethodName = this.callAPIConstants.GetColumnReportsolutionTitle;
      } else if (this.data.colName === 'Author') {
        this.getMethodName =
          this.callAPIConstants.GetColumnReportsolutionAuthor;
      }
    } else if (this.type === 'reportlist') {
      if (this.data.colName === 'Region') {
        this.getMethodName =
          this.callAPIConstants.GetColumnReportIncidentRegion;
      } else if (this.data.colName === 'Author') {
        this.getMethodName =
          this.callAPIConstants.GetColumnReportIncidentAuthor;
      } else if (this.data.colName === 'Department') {
        this.getMethodName =
          this.callAPIConstants.GetColumnReportIncidentDepartment;
      } else {
        this.getMethodName = this.callAPIConstants.GetColumnReportIncidentTitle;
      }
    } else if (this.type === 'subRegionlist') {
      if (this.data.colName === 'Region') {
        this.getMethodName = this.callAPIConstants.GetColumnRegion;
      } else {
        this.getMethodName = this.callAPIConstants.GetColumnSubRegion;
      }
    }
    this.loadTypehead();
  }

  /*************************************************************
  @Purpose     : Load TypeAhead Initially
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  private loadTypehead() {
    this.filterlist = concat(
      of([]),
      this.typeAheadSource.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => this.getFilterSearchList(term)),
        map((response) => {
          return response;
        })
      )
    );
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : Get List of Filter on Search
  @Parameter   : value
  @Return      : items
  /*************************************************************/
  loader = false;
  getFilterSearchList(value: string = null): Observable<any> {
    this.loader = true;
    let dataParams: any;
    let column: any;

    dataParams = {
      page: 1,
      pagesize: 10,
      column: this.data.colFieldname,
      filter: [{ [this.data.colFieldname]: [value] }],
    };
    if (this.filteredArray.length) {
      dataParams['filteredArray'] = this.filteredArray;
    }
    let items = [];
    return this.commonService
      .callApiObservable(this.getMethodName, dataParams)
      .pipe(
        catchError(() => of({ items: [] })),
        map((success) => {
          if (success['status'] == 1) {
            value ? (items = success['data']['listing']) : (items = []);
            this.loader = false;
          }
          return items ? items : [];
        })
      );
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : Value emits on Selection
  @Parameter   : fieldName, $event
  @Return      : Breadcrumbs
  /*************************************************************/
  public filteredArray: any[] = [];
  selectFilterValue(fieldName, event) {
    if (this.type === 'subRegionlist' && this.data.colName === 'REGION') {
      fieldName = 'parentRegion';
    } else if (this.type === 'domainlist') {
      if (this.data.colName === 'Region') {
        fieldName = 'region';
      } else {
        fieldName = 'subRegion';
      }
    } else if (this.type === 'incidentlist') {
      if (this.data.colName === 'REGION') {
        fieldName = 'incidentRegion';
      } else if (this.data.colName === 'DEPARTMENT') {
        fieldName = 'incidentDepartment';
      } else if (this.data.colName === 'USER') {
        fieldName = 'author';
      } else {
        fieldName = 'incidentTitle';
      }
    } else if (this.type === 'solutionlist') {
      if (this.data.colName === 'Solution') {
        fieldName = 'title';
      } else if (this.data.colName === 'Author') {
        fieldName = 'author';
      } else {
        fieldName = 'parentIncident';
      }
    } else if (this.type === 'reportsolutionlist') {
      if (this.data.colName === 'Solution') {
        fieldName = 'title';
      } else if (this.data.colName === 'Author') {
        fieldName = 'author';
      }
    } else if (this.type === 'reportlist') {
      if (this.data.colName === 'Region') {
        fieldName = 'incidentRegion';
      } else if (this.data.colName === 'Author') {
        fieldName = 'author';
      } else if (this.data.colName === 'Department') {
        fieldName = 'incidentDepartment';
      } else {
        fieldName = 'incidentTitle';
      }
    } else if (this.type === 'subRegionlist') {
      if (this.data.colName === 'Region') {
        fieldName = 'parentRegion';
      } else {
        fieldName = 'name';
      }
    }
    this.filteredArray = event;
    this.selectedValueOfFilter.emit({ fieldName, value: event });
  }
  /***************************************************************/
}
