<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{ bsModalRef?.content?.modalData?.name ? 'Edit Domain' : 'Add Domain' }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <!-- <div class="card-body">
      <div class="container">
        <div class="form-group">
          <label>Industry<sup class="bx bxs-star danger"></sup></label>
          <ng-select
            class="text-input"
            name="industry"
            [items]="industryArr"
            [clearable]="true"
            bindValue="value"
            bindLabel="name"
            #industry="ngModel"
            [(ngModel)]="domainData.industry"
            [searchable]="true"
            autocomplete="industry"
            placeholder="Select Industry "
            (change)="industryChange()"
            required
          >
          </ng-select>
          <div
            style="color: indianred"
            class="error-block text-left"
            *ngIf="(submitted || industry.touched) && industry.invalid"
          >
            Select industry
          </div>
        </div>
      </div>
    </div> -->
    <form
      *ngIf="checkIndustry('healthcare', 'aviation')"
      #form="ngForm"
      (ngSubmit)="createUpdate(form, domainData)"
    >
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label
              >Domain/Company Title<sup class="bx bxs-star danger"></sup
            ></label>
            <div
              class=""
              [ngClass]="
                (submitted || name.touched) && name.invalid ? 'has-danger' : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter Domain "
                name="name"
                id="name"
                #name="ngModel"
                [(ngModel)]="domainData.name"
                required
              />
              <div
                class="error-block text-left"
                *ngIf="(submitted || name.touched) && name.invalid"
              >
                Enter Domain name
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Domain/Company Display Name</label>
            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Domain Display Name"
              name="displayName"
              id="displayName"
              #name="ngModel"
              [(ngModel)]="domainData.displayName"
            />
          </div>
          <div class="form-details">
            <div *ngIf="flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="ParentRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.domainData.region"
                name="region"
                #region="ngModel"
                required
                (change)="onchange($event)"
                [searchable]="true"
                placeholder="Select Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || region.touched) && region.invalid"
              >
                Select region
              </div>
            </div>
            <div *ngIf="!flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="ParentRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.domainData.region"
                bindValue="_id"
                name="region"
                #region="ngModel"
                required
                (change)="onchange($event)"
                [searchable]="true"
                placeholder="Select Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || region.touched) && region.invalid"
              >
                Select region
              </div>
            </div>
          </div>
          <div class="form-details">
            <div *ngIf="this.flag" class="form-group">
              <label>Sub-Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="subRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.subRegion"
                name="subRegion"
                #subRegion="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Sub Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || subRegion.touched) && subRegion.invalid"
              >
                Select subregion Head
              </div>
            </div>
            <div *ngIf="!this.flag" class="form-group">
              <label>Sub-Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="subRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.subRegion"
                bindValue="_id"
                name="subRegion"
                #subRegion="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select sub Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || subRegion.touched) && subRegion.invalid"
              >
                Select subregion Head
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="domainData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>

    <form
      *ngIf="checkIndustry('aviation', 'healthcare')"
      #aviationForm="ngForm"
      (ngSubmit)="createUpdateAviationDomain(aviationForm, domainData)"
    >
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label
              >Domain/Company Title<sup class="bx bxs-star danger"></sup
            ></label>
            <div
              class=""
              [ngClass]="
                (submitted || name.touched) && name.invalid ? 'has-danger' : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter Domain "
                name="name"
                id="name"
                #name="ngModel"
                [(ngModel)]="domainData.name"
                required
              />
              <div
                class="error-block text-left"
                *ngIf="(submitted || name.touched) && name.invalid"
              >
                Enter Domain name
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Domain/Company Display Name</label>
            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Domain Display Name"
              name="displayName"
              id="displayName"
              #name="ngModel"
              [(ngModel)]="domainData.displayName"
            />
          </div>

          <div class="form-details">
            <div *ngIf="flag" class="form-group">
              <label>Country<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="countryList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.domainData.country"
                name="country"
                #country="ngModel"
                required
                bindValue="name"
                (change)="onChangeCountry($event)"
                [searchable]="true"
                placeholder="Select Country"
                autocomplete="country"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || country.touched) && country.invalid"
              >
                Select country
              </div>
            </div>
            <div *ngIf="!flag" class="form-group">
              <label>Country<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="countryList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.domainData.country"
                bindValue="name"
                name="country"
                #country="ngModel"
                required
                (change)="onChangeCountry($event)"
                [searchable]="true"
                placeholder="Select Country "
                autocomplete="country"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || country.touched) && country.invalid"
              >
                Select country
              </div>
            </div>
          </div>

          <div class="form-details">
            <div *ngIf="flag" class="form-group">
              <label>Type<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="typeList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.domainData.aviationType"
                name="aviationType"
                #aviationType="ngModel"
                required
                bindValue="name"
                (change)="onchange($event)"
                [searchable]="true"
                placeholder="Select Type"
                autocomplete="type"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || aviationType.touched) && aviationType.invalid
                "
              >
                Select type
              </div>
            </div>
            <div *ngIf="!flag" class="form-group">
              <label>Type<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="typeList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.domainData.aviationType"
                bindValue="name"
                name="aviationType"
                #aviationType="ngModel"
                required
                (change)="onchange($event)"
                [searchable]="true"
                placeholder="Select Type "
                autocomplete="type"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || aviationType.touched) && aviationType.invalid
                "
              >
                Select type
              </div>
            </div>
          </div>
          <div
            *ngIf="
              this.domainData.aviationType == 'Commercial' ||
              this.domainData.aviationType == 'Private'
            "
            class="form-details"
          >
            <div *ngIf="this.flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="aviationRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.region"
                name="region"
                #region="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || region.touched) && region.invalid"
              >
                Select region
              </div>
            </div>
            <div *ngIf="!this.flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="aviationRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.region"
                bindValue="_id"
                name="region"
                #region="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Region"
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || region.touched) && region.invalid"
              >
                Select region
              </div>
            </div>
          </div>

          <div
            *ngIf="this.domainData.aviationType == 'Airline'"
            class="form-details"
          >
            <div *ngIf="this.flag" class="form-group">
              <label>Airline<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="airlineList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.airline"
                name="airline"
                #airline="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Airline "
                autocomplete="airline"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || airline.touched) && airline.invalid"
              >
                Select airline
              </div>
            </div>
            <div *ngIf="!this.flag" class="form-group">
              <label>Airline<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="airlineList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.airline"
                bindValue="_id"
                name="airline"
                #airline="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Airline"
                autocomplete="airline"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || airline.touched) && airline.invalid"
              >
                Select airline
              </div>
            </div>
          </div>

          <div
            *ngIf="this.domainData.aviationType == 'Military'"
            class="form-details"
          >
            <div *ngIf="this.flag" class="form-group">
              <label
                >Place of service<sup class="bx bxs-star danger"></sup
              ></label>

              <ng-select
                class="text-input"
                [items]="placeOfServiceList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.placeOfService"
                name="placeOfService"
                #placeOfService="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Place of Service "
                autocomplete="placeOfService"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || placeOfService.touched) &&
                  placeOfService.invalid
                "
              >
                Select place of service
              </div>
            </div>
            <div *ngIf="!this.flag" class="form-group">
              <label
                >Place of Service<sup class="bx bxs-star danger"></sup
              ></label>

              <ng-select
                class="text-input"
                [items]="placeOfServiceList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="domainData.placeOfService"
                bindValue="_id"
                name="placeOfService"
                #placeOfService="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Place of Service"
                autocomplete="placeOfService"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || placeOfService.touched) &&
                  placeOfService.invalid
                "
              >
                Select place of service
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="domainData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
