<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{ bsModalRef?.content?.modalData?.name ? 'Edit Tag' : 'Add Tag' }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <!-- <form #form="ngForm" (ngSubmit)="createUpdate(form, tagData)"> -->
    <form
      #form="ngForm"
      [formGroup]="addAndEditTagForm"
      (ngSubmit)="createUpdate(form, tagData)"
    >
      <div class="card-body">
        <div class="container">
          <!-- <div class="form-group">
            <label>Industry<sup class="bx bxs-star danger"></sup></label>
            <ng-select
              class="text-input"
              name="industry"
              [items]="industryArr"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #industry="ngModel"
              [(ngModel)]="tagData.industry"
              [searchable]="true"
              autocomplete="industry"
              placeholder="Select Industry "
              [ngModelOptions]="{ standalone: true }"
              required
              (change)="setValueForIndustry('industry', $event)"
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || industry.touched) && industry.invalid"
            >
              Select industry
            </div>
          </div> -->
          <div class="form-group">
            <label>Tags Title<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || addAndEditTagForm.controls.name.touched) &&
                addAndEditTagForm.controls.name.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter Tag "
                name="name"
                id="name"
                [(ngModel)]="tagData.name"
                required
                [ngModelOptions]="{ standalone: true }"
                formControlName="name"
              />
              <div
                class="error-block text-left"
                *ngIf="
                  (submitted || addAndEditTagForm.controls.name.touched) &&
                  addAndEditTagForm.controls.name.invalid
                "
              >
                Enter Tag name
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>
            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="tagData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
              [ngModelOptions]="{ standalone: true }"
              (change)="setValueForSelect('isActive', $event)"
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="form-group">
            <label for="definition">Tag Definition</label>
            <textarea
              class="form-control"
              id="definition"
              placeholder="Enter tag definition"
              rows="5"
              name="definition"
              formControlName="definition"
              [(ngModel)]="tagData.definition"
              [ngModelOptions]="{ standalone: true }"
            ></textarea>
          </div>

          <div class="form-group">
            <div class="d-flex flex-row align-items-center">
              <div>Add keyword</div>
              <span
                (click)="addKeywords()"
                tooltip="Add keyword"
                style="cursor: pointer"
                placement="right"
                class="bx bxs-plus-circle ml-2"
              ></span>
            </div>
          </div>

          <div class="form-group">
            <div formArrayName="keywords">
              <div
                *ngFor="let keyword of keywords().controls; let i = index"
                [formGroupName]="i"
              >
                <div
                  class="d-flex flex-row justify-content-center align-items-center mt-2"
                >
                  <input
                    type="text"
                    formControlName="keyword"
                    class="form-control"
                    placeholder="keyword  {{ i + 1 }}"
                  />
                  <span
                    (click)="removeKeywords(i)"
                    tooltip="Remove keyword"
                    style="cursor: pointer"
                    placement="right"
                    class="bx bxs-minus-circle ml-2"
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Language<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="language"
              [items]="shortCodeList"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #language="ngModel"
              [(ngModel)]="tagData.language"
              [searchable]="true"
              autocomplete="shortcode"
              placeholder="Enter language"
              [ngModelOptions]="{ standalone: true }"
              (change)="setValueForLang('language', $event)"
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || language.touched) && language.invalid"
            >
              Enter language
            </div>
          </div>
          <div *ngIf="otherLangSelected" class="form-group">
            <label>Parent Tag<sup class="bx bxs-star danger"></sup></label>
            <ng-select
              class="text-input"
              name="parentId"
              [items]="pTagList | async"
              [clearable]="true"
              bindValue="name"
              bindLabel="name"
              #parentId="ngModel"
              [(ngModel)]="tagData.parentId"
              [searchable]="true"
              [typeaheadMinLength]="0"
              [notFoundText]="notFoundText1"
              autocomplete="parentId"
              placeholder="Enter Parent Tag "
              (change)="onParentTag($event)"
              [typeahead]="internalAssigneDataSource"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select Tag
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button type="submit" class="btn btn-primary mr-2">
              {{ buttonName }}
            </button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
