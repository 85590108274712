<div class="theme-setting-block" [ngClass]="{ open: isSettingOpen }">
  <a class="theme-setting-link" (click)="openSettingBox()">
    <i
      class="bx bx-cog bx-flip-horizontal"
      [ngClass]="{ 'bx-spin': !isSettingOpen }"
    ></i>
  </a>
  <div class="d-flex align-items-center">
    <span class="light-icon icon mr-1 d-block"
      ><em class="bx bx-sun"></em
    ></span>
    <div class="custom-control custom-switch theme-switch">
      <input
        type="checkbox"
        class="custom-control-input"
        id="otpSwitchTheme"
        (click)="toggleTheme()"
      />
      <label class="custom-control-label" for="otpSwitchTheme"></label>
    </div>
    <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
  </div>
</div>

<!--Start Login Wrapper-->
<div
  class="login-wrapper"
  style="
    background: url(assets/images/login-bg.jpg) no-repeat center center;
    background-size: cover;
  "
>
  <div class="login-body">
    <section class="login-container row m-0">
      <div class="col-xl-8 col-11">
        <div class="card bg-login-card">
          <div class="row m-0">
            <div class="col-md-6 col-12 pl-0 pr-0">
              <div
                class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100"
              >
                <a routerLink="/register" class="back d-flex align-items-center"
                  ><i class="bx bxs-chevron-left"></i
                  ><span class="d-block">Back</span></a
                >
                <div class="card-header">
                  <div class="card-title text-center mb-4">
                    <h4 class="mb-2 mt-2">Verify your Mobile Number</h4>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="text-center mb-4">
                      <small class="d-block common-small-text"
                        >A 6-digit code has been sent to<span
                          class="d-block text-primary"
                          >+91 {{ phoneNumber }}</span
                        ></small
                      >
                    </div>
                    <form
                      class="digit-group pt-2 pb-2 d-block"
                      data-group-name="digits"
                      data-autosubmit="false"
                      autocomplete="off"
                    >
                      <div
                        class="otp-input-number d-flex align-items-center justify-content-center mb-3"
                      >
                        <ng-otp-input
                          #ngOtpInput
                          (onInputChange)="onOtpChange($event)"
                          [config]="config"
                        ></ng-otp-input>
                      </div>
                      <div class="form-group mb-2">
                        <div class="invalid-feedback" style="display: block">
                          <div>{{ errorMsg }}</div>
                        </div>
                      </div>

                      <button
                        (click)="onVerify()"
                        class="btn btn-primary glow position-relative btn-block"
                      >
                        Verify Otp<i
                          class="icon-arrow bx bx-right-arrow-alt"
                        ></i>
                      </button>
                    </form>
                    <div class="text-center common-small-text mt-4 mb-2">
                      <small>The OTP will be expired in</small>
                      <small class="text-primary">
                        {{ timeLeftInMinutes }}</small
                      >
                    </div>
                    <div class="text-center common-small-text mt-2 mb-5">
                      <small>Didn’t receive the code?</small>
                      <a style="cursor: pointer" (click)="resendOTP()"
                        ><small> Resend</small></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 d-md-block d-none text-center align-self-center p-7"
            >
              <div class="card-content">
                <picture>
                  <source
                    srcset="assets/images/forgot-password.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="assets/images/forgot-password.png"
                    type="image/png"
                  />
                  <img
                    class="img-fluid"
                    src="assets/images/forgot-password.png"
                    alt="forgot password"
                    width="300"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!--End Login Wrapper-->
