import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderTitleService {
  private selectedItemSource: BehaviorSubject<string>;
  selectedItem$;
  constructor() {
    const savedItem = localStorage.getItem('selectedItem') || '';
    this.selectedItemSource = new BehaviorSubject<string>(savedItem);
    this.selectedItem$ = this.selectedItemSource.asObservable();
  }
  setSelectedItem(item: string) {
    this.selectedItemSource.next(item);
    localStorage.setItem('selectedItem', item);
  }
}
