<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <div class="icon mr-2"><span class="bx bxs-plus-circle"></span></div>
    <h5 class="mb-0">
      {{ id ? 'Update' : 'Add' }}
      {{
        type == 'countriesListing'
          ? 'Country'
          : type == 'currencyListing'
          ? 'Currency'
          : type == 'timezoneListing'
          ? 'Timezone'
          : 'Another'
      }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm">
      <div class="card-body">
        <div class="container">
          <div class="form-group" *ngIf="type == 'countriesListing'">
            <label>Country Name<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || countryName.touched) && countryName.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <ng-select
                [items]="countries"
                type="text"
                name="countryName"
                bindLabel="name"
                bindValue="name"
                placeholder="Select Country"
                #countryName="ngModel"
                [(ngModel)]="commonObj.countryName"
                (change)="onOptionsSelectedForCountries($event)"
                required
              >
              </ng-select>

              <div
                class="error-block text-left"
                *ngIf="
                  (submitted || countryName.touched) && countryName.invalid
                "
              >
                {{
                  showErrorService.showError('countryName', countryName.errors)
                }}
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="type == 'countriesListing'">
            <label>Country Code<sup class="bx bxs-star danger"></sup></label>

            <div
              class=""
              [ngClass]="
                (submitted || countryCode.touched) && countryCode.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                disabled
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Country code"
                name="countryCode"
                #countryCode="ngModel"
                [(ngModel)]="commonObj.countryCode"
                required
              />

              <div
                class="error-block text-left"
                *ngIf="
                  (submitted || countryCode.touched) && countryCode.invalid
                "
              >
                {{
                  showErrorService.showError('countryCode', countryCode.errors)
                }}
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="type == 'countriesListing'">
            <label>Phone Code<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || phoneCode.touched) && phoneCode.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                disabled
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Phone code"
                name="phoneCode"
                #phoneCode="ngModel"
                [(ngModel)]="commonObj.phoneCode"
                required
              />

              <div
                class="error-block text-left"
                *ngIf="(submitted || phoneCode.touched) && phoneCode.invalid"
              >
                {{ showErrorService.showError('phoneCode', phoneCode.errors) }}
              </div>
            </div>
          </div>

          <div
            class="form-group"
            *ngIf="type == 'currencyListing' || type == 'timezoneListing'"
          >
            <label>Country<sup class="bx bxs-star danger"></sup></label>

            <div
              class=""
              [ngClass]="
                (submitted || countryId.touched) && countryId.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <ng-select
                class="ng-select-line"
                [items]="countryArr"
                bindLabel="countryName"
                bindValue="_id"
                placeholder="Select Country"
                name="countryId"
                #countryId="ngModel"
                [(ngModel)]="commonObj.countryId"
                (change)="onOptionsSelected($event)"
                required
              >
              </ng-select>

              <div
                class="error-block text-left"
                *ngIf="(submitted || countryId.touched) && countryId.invalid"
              >
                {{ showErrorService.showError('countryId', countryId.errors) }}
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="type === 'currencyListing'">
            <label>Currency:</label>
            <div class="">
              <ng-select
                [items]="commonObj.filterCurrencies"
                type="text"
                name="currency"
                bindLabel="currencyCode"
                bindValue="currencyCode"
                placeholder="Select currency"
                #currency="ngModel"
                [(ngModel)]="commonObj.currency"
                required
              >
              </ng-select>
              <!-- <input disabled autocomplete="off" class="form-control" type="text" placeholder="" name="filterCurrencies" [(ngModel)]="commonObj.filterCurrencies" required> -->
              <div
                class="error-block text-left"
                *ngIf="(submitted || currency.touched) && currency.invalid"
              >
                {{ showErrorService.showError('currency', currency.errors) }}
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="type === 'timezoneListing'">
            <label>Timezone<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || timezone.touched) && timezone.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <ng-select
                [items]="commonObj.filteredTimezones"
                type="text"
                name="timezone"
                bindLabel="timezone"
                bindValue="timezone"
                placeholder="Select Timezone"
                #timezone="ngModel"
                [(ngModel)]="commonObj.timezone"
                required
              >
              </ng-select>
              <!-- <input disabled autocomplete="off" class="form-control" type="text" placeholder="Country code" name="filteredTimezones" #filteredTimezones="ngModel" [(ngModel)]="commonObj.filteredTimezones" required> -->

              <div
                class="error-block text-left"
                *ngIf="(submitted || timezone.touched) && timezone.invalid"
              >
                {{ showErrorService.showError('timezone', timezone.errors) }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button
              type="submit"
              class="btn btn-primary mr-2"
              (click)="createUpdate(form, commonObj)"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
