<ng-select
  class="ng-select-line"
  [items]="filterlist | async"
  placeholder="Enter {{ data.colName }}"
  [clearable]="false"
  [multiple]="true"
  [dropdownPosition]="'auto'"
  [closeOnSelect]="true"
  (change)="selectFilterValue(data.colFieldname, $event)"
  [(ngModel)]="data.value"
  [notFoundText]="'Data not found'"
  (keydown)="
    data.colName == 'E-mail'
      ? ''
      : data.colName == 'Mobile'
      ? restrictKeyPressService.AllowNumbers($event)
      : restrictKeyPressService.AllowChar($event)
  "
  required
  [typeaheadMinLength]="10000000"
  [typeahead]="typeAheadSource"
  [loading]="loader"
>
</ng-select>
