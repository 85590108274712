import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-add-training',
  templateUrl: './add-training.component.html',
  styleUrls: ['./add-training.component.scss'],
})
export class AddTrainingComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  TrainingData: any = {};
  buttonName: any = 'Add';

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData?.type === 'edit_deptlist') {
        this.buttonName = 'Update';

        this.TrainingData.trainingId =
          this.bsModalRef?.content?.modalData?.trainingId;
        this.TrainingData.name = this.bsModalRef?.content?.modalData?.name;
        this.TrainingData.isActive =
          this.bsModalRef?.content?.modalData?.isActive;
      }
    });
  }
  /****************************************************************************
  @Purpose     : Add/Update training
  @Parameter   : form, training
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.TrainingData.trainingId) {
      let dataObj = {
        trainingId: this.TrainingData.trainingId,
        name: form.value.name,
        isActive: form.value.isActive,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditTraining + '/' + dataObj.trainingId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.router.navigate([this.URLConstants.TRAINING]);

              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.TRAINING]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.AddTraining, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.TRAINING]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
