import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-remove-report',
  templateUrl: './remove-report.component.html',
  styleUrls: ['./remove-report.component.scss'],
})
export class RemoveReportComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  Data: any;
  ReasonForm: FormGroup;

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.ReasonForm = this.fb.group({
      adminRejectionReason: ['', [Validators.required]],
    });
    setTimeout(() => {
      this.Data = this.bsModalRef?.content?.modalData.data._id;
    });
  }

  saveReason() {
    let dataObj = {
      incidentId: this.Data,
      adminRejectionReason: this.ReasonForm.value.adminRejectionReason,
    };

    this.commonService
      .callApi(
        this.callAPIConstants.RejectChangeStatus,
        dataObj,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.broadCasterService.broadcast('listingAddUpdate', true);
          this.showErrorService.popToast('success', success.message);
          this.bsModalRef.hide();
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }
}
