import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SortService {
  constructor() {}

  private columnSortedSource = new Subject<IColumnSortedEvent>();

  columnSorted$ = this.columnSortedSource.asObservable();

  columnSorted(event: IColumnSortedEvent) {
    this.columnSortedSource.next(event);
  }
}

// tslint:disable-next-line: interface-name
export interface IColumnSortedEvent {
  sortColumn: string;
  sortDirection: string;
  objectName: string;
}
