export const messages = {
  ERROR: {
    REQUIRED: {
      Email: 'Enter Email Address',
      Password: 'Enter Password',
      Username: 'Enter Username',
      currentPassword: 'Enter Current Password',
      newPassword: 'Enter New Password',
      confirmPassword: 'Enter Confirm Password',
      firstname: 'Enter First Name',
      lastname: 'Enter Last Name',
      firstName: 'Enter First Name',
      lastName: 'Enter Last Name',
      userName: 'Enter User Name',
      companyName: 'Enter Company Name',
      mobile: 'Enter Mobile Number',
      emailId: 'Enter Email',
      roleName: 'Enter Role',
      role: 'Enter Role',
      PhoneNo: ' Enter Phone Number',
      Amount: ' Enter Amount',
      filterName: 'Enter Name of Filter',
      emailKey: 'Please enter title',
      subject: 'Please enter subject',
      fromEmailId: 'Please enter email',
      tag: 'please add tag',
      emailTemplateId: 'Select Event',
      host: 'Enter Host',
      port: 'Enter Port',
      category: 'Enter Blog Category',
      description: 'Enter Description',
      categoryName: 'Select Category',
      faqsCategory: 'Enter Category',
      answer: 'Enter Answer',
      question: 'Enter Question',
      image: 'select Image',
      caption: 'caption',

      blogTitle: 'Enter Blog Title',
      slug: 'Enter Slug',
      defaultFromEmail: 'Enter Default From EMail',
      defaultAdminEmail: 'Enter Default Admin Email',
      metaTitle: 'Enter Meta Title',
      metaDescription: 'Enter Meta Description',
      metaKeyword: 'Enter Meta Keyword',
      pageTitle: 'Enter Page Title',
      currency: 'Select Currency',
      mainCurrency: 'Enter Currency',
      dateFormat: 'Select Date Format',
      templateName: 'Enter Template Name',
      countryName: 'Enter Country Name',
      countryId: 'Enter Country Name',
      countryCode: 'Enter Country Code',
      phoneCode: 'Enter Phone Code',
      facebookAppId: 'Enter Facebook App Id',
      googleToken: 'Enter Google OAuth Token',
      pKey: 'Enter Publishable Key',
      sKey: 'Enter Secret Key',
      mode: 'Enter Mode',
      clientSecret: 'Enter Client Secret',
      clientId: 'Enter Client Id',
      timezone: 'Enter Timezone',
      twitterLink: 'Enter Twitter Link',
      facebookLink: 'Enter Facebook Link',
      googleLink: 'Enter Google Link',
      linkedinLink: 'Enter Linkedin Link',
      instagramLink: 'Enter Instagram Link',
      websiteLink: 'Enter Website Link',
      dob: 'Select Date Of Birth',
      country: 'Select Country',
      address: 'Enter Address',
      languages: 'Select language',
      footerSnippet: 'Enter Footer Snippet',
      headerSnippet: 'Enter Header Snippet',
      analyticsSnippet: 'Enter Analytics Snippet',
      authToken: 'Enter Auth Token',
      accountSid: 'Enter Account SID',
      mobileNo: 'Enter Mobile Number',
      infographicTitle: 'Enter title',
      infographicMethod: 'Select method',
      infographicChartType: 'Select chart type',
      // description: 'Enter Description'
    },
    PATTERN: {
      Email: 'Enter Valid Email Address',
      Contactno: 'Invalid Contact Number',
      emailId: 'Enter valid Email',
      fromEmailId: 'Enter valid Email',
      adminEmail: 'Enter valid Email',
      newPassword:
        'Enter atleast 8 characters with one uppercase, one digit and one special character',
      confirmPassword: 'Enter Valid Password',
      link: 'url should contain https://',
    },
    MINLENGTH: {
      answer: 'Answer must be minimum 20 characters.',
      question: 'Question must be minimum 10 characters.',
      description: 'Blog description should be minimum 20 characters',
      category: 'Blog category should be minimum 2 characters',
      mobile: 'Mobile Number should b e 10 digits',
      PhoneNo: 'Mobile Number should be 10 digits',
      confirmPassword: 'Require atleast 6 characters',
      newPassword: 'Require atleast 6 characters',
      port: 'Minimum 3 didgit required',
    },
    CUSTOM: {
      ConfirmPassword: 'Confirm password does not match!',
    },
  },
};
