import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import * as countries from 'src/app/common/constants/json/countries.json';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-add-airline',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  regionData: any = {};
  buttonName: any = 'Add';
  public countries: any = countries.default;

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData?.type === 'edit_regionlist') {
        this.buttonName = 'Update';

        this.regionData.regionId =
          this.bsModalRef?.content?.modalData?.regionId;
        this.regionData.name = this.bsModalRef?.content?.modalData?.name;
        this.regionData.isActive =
          this.bsModalRef?.content?.modalData?.isActive;
        this.regionData.country = this.bsModalRef?.content?.modalData?.country;
      }
    });
  }

  /****************************************************************************
  @Purpose     : Add/Update Region
  @Parameter   : form, RegionData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.regionData.regionId) {
      let dataObj = {
        serviceId: this.regionData.regionId,
        name: form.value.name,
        isActive: form.value.isActive,
        country: form.value.country,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditService + '/' + dataObj.serviceId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.DASHBOARD]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.AddService, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.SERVICE]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
