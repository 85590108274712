import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, Subject, concat } from 'rxjs';
import {
  merge,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map,
  startWith,
} from 'rxjs/operators';

// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-duplicate-questionnaire',
  templateUrl: './duplicate-questionnaire.component.html',
  styleUrls: ['./duplicate-questionnaire.component.scss'],
})
export class DuplicateQuestionnaireComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  //observable assign
  public domainList: Observable<any>;
  public domainList1: Observable<any>;
  public internalAssigneDataSource = new Subject<any>();
  public internalAssigneLoader: boolean = false;
  public focus$ = new Subject<string>();
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  questionnaireData: any = {};
  buttonName: any = 'Add';
  devChecklistId: any;
  modalData: any;
  notFoundText1: any;
  questionnaireLanguage: any;
  public types = [];
  public typesEn = [
    {
      generalType: 'Blood Product',
      specificTypes: [
        'Blood Product Administration Issue',
        'Blood Product Delivery Issue',
        'Blood Product Wasted',
        'Ordering Issue',
        'Other (please specify)',
        'Transfusion Laboratory Process Issue',
        'Transfusion Reaction',
      ],
    },
    {
      generalType: 'Defective Product/ Recall Notification',
      specificTypes: [
        'Disposable/Single Use Product Issue',
        'Other (please specify)',
        'Reprocessable Product Issue',
      ],
    },
    {
      generalType: 'Diagnosis/Treatment',
      specificTypes: [
        'Allergic Reaction (Non Medication-related)',
        'Delay in Treatment/Results',
        'Delay/Lack of Response to Patient Condition (e.g. not assessed/deterioration not recognized)',
        'Diagnosis - Delayed',
        'Diagnosis Issue',
        'Other (please specify)',
        'Treatment - Inappropriate',
        'Treatment - Incorrectly Performed',
        'Unexpected Patient Cardiopulmonary and/or Respiratory Arrest',
        'Workplace Incident',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Emergency Code Call',
      specificTypes: [
        'Adult Support (Illness/Injury in adult)',
        'Code Blue (Life Threatening Medical Event)',
        'Code Brown (Hazardous spill/decontamination)',
        'Code ED (High volume/acuity in ED)',
        'Code Green (Evacuation)',
        'Code Grey (IS/Facilities failure/loss)',
        'Code Orange (Disaster/Mass Casualty Incident)',
        'Code Pink (Neonatal Resuscitation)',
        'Code Red (Fire)',
        'Code Transfusion (8888)',
        'Code White (Behavior with risk of harm)',
        'Code Yellow (Missing/abducted pt/child)',
        'Lockdown',
        'Penetrating Trauma',
        'Shelter in place',
        'SPOT (Child/pt worsening condition)',
        'STAT',
        'Trauma',
      ],
    },
    {
      generalType: 'Equipment/Medical Device',
      specificTypes: [
        'Broken Item',
        'Disconnected',
        'Disposable/Single Use Product Issue',
        'Electrical Issue',
        'Equipment - Faulty',
        'Equipment - Not Available',
        'Equipment Malfunction',
        'Reprocessable Product Issue',
      ],
    },
    {
      generalType: 'Fall/Injury',
      specificTypes: [
        'Fall',
        'Injury',
        'Other (please specify)',
        'Workplace Incident',
      ],
    },
    {
      generalType: 'High Risk Patient Behaviour',
      specificTypes: [
        'Assault',
        'AWOL/Missing',
        'Behavioural Issue',
        'Contraband item(s) found',
        'Other (please specify)',
        'Restraint Related',
        'Restraint Related Issue',
        'Self Harm',
        'Suicidal Gesture/Attempt',
        'Workplace Hazard',
        'Workplace Incident',
      ],
    },
    {
      generalType: 'Infection',
      specificTypes: [
        'Additional Precautions Issue',
        'Hospital Acquired Infection/Colonization',
        'Other (please specify)',
        'Patient Exposure - Blood/Body Fluid',
        'Patient Exposure - Other',
        'Routine Practices Issue',
        'Sterilization/Disinfection/Reprocessing Issue',
        'Workplace Hazard',
      ],
    },
    {
      generalType: 'IV/Vascular Access Device',
      specificTypes: [
        'Catheter or Tubing Cracked/Damaged/Severed',
        'CVL Maintenance Bundle Issue',
        'Disconnected',
        'Dislodged/Displaced',
        'Dislodgement/Displaced',
        'Infiltration/Extravasation',
        'Insertion',
        'Multiple Attempts/Unnecessary Blood Draw/Attempts',
        'Occlusion',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Policy/Procedure Not Followed (CVL Maintenance Bundle Issue)',
        'Routine Practices Issue',
        'Tubing Not Changed',
        'Wrong Lumen Accessed',
      ],
    },
    {
      generalType: 'Lab/Specimen',
      specificTypes: [
        'Collection Issue',
        'Critical Value Issue',
        'Handling/Storage Issue',
        'Labeling/ID Issue',
        'Labelling Signature Issue',
        'Laboratory Equipment/Instrument Issue',
        'Laboratory Supply Issue',
        'Ordering/Requisition Issue',
        'Other (please specify)',
        'Result Delayed',
        'Result Incorrect',
        'Result Not Reported',
        'Result Reported to Wrong Patient',
        'Specimen Accessioning Issue',
        'Specimen Receiving Issue',
        'Specimen Transport Issue',
      ],
    },
    {
      generalType: 'Line/Tube',
      specificTypes: [
        'Blocked/Occluded',
        'Broken/Perforated/Severed',
        'Care Protocol Not Followed (e.g. not changed)',
        'Disconnected',
        'Discontinued Without Order',
        'Dislodged/Displaced',
        'Dislodgement/Displaced',
        'Insertion',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Wrong Insertion Location',
        'Wrong Size Inserted',
        'Wrong Tube Type Inserted',
      ],
    },
    {
      generalType: 'Medical Imaging',
      specificTypes: [
        'Contrast - Allergic Reaction',
        'Contrast - Event',
        'Contrast - Extravasation',
        'Delay in Treatment/Results',
        'Exposure - Overexposure',
        'Image - Destroyed/Lost',
        'Image - Mislabeled/Incorrect',
        'MRI Safety Issue',
        'Ordering/Requisition Issue',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Results - Incorrect Reading',
        'Test - Ordered, Not Performed',
        'Test - Wrong Procedure',
        'Test - Wrong Side (L vs R)',
        'Test - Wrong Site',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Medication/IV Solution',
      specificTypes: [
        'Adverse Drug Reaction',
        'Barcode Issue',
        'Contraindicated - Known Allergy',
        'Contraindication-Known Allergy/Intolerance',
        'Delivered to wrong unit',
        'Drug incompatibility',
        'Duplicate Orders',
        'Expired Product',
        'Extra dose',
        'Late dose (>1hr)',
        'Late dose/delay in Treatment',
        'Medication Reconciliation',
        'Medication Reconciliation Issue',
        'Missed dose',
        'Missing Drug (non-narcotic)',
        'Narcotic Discrepancy',
        'Ordering Issue',
        'Other (please specify)',
        'Pain Management Inadequate',
        'Product damaged',
        'Product integrity',
        'Product integrity issue',
        'Pump Programming',
        'Pump Programming Issue',
        'Storage Incorrect/Improper Disposal',
        'Therapeutic Drug Level Monitoring',
        'Therapeutic Drug Level Monitoring Issue',
        'Wrong Concentration',
        'Wrong Dose',
        'Wrong Drug',
        'Wrong Formulation',
        'Wrong Patient',
        'Wrong Rate',
        'Wrong Route',
        'Wrong Site',
        'Wrong Solution',
      ],
    },
    {
      generalType: 'Morbidity/Mortality Review',
      specificTypes: ['Morbidity', 'Mortality'],
    },
    {
      generalType: 'Nutrition',
      specificTypes: [
        'Allergic Reaction (Non Medication-related)',
        'Allergy Alert Not Followed / Documented',
        'Delayed Nutrition',
        'Feeding Protocol / Order Not Followed',
        'Incorrect / Missing Portion',
        'Labeling/ID Issue',
        'NPO Guidelines Not Followed',
        'Ordering Issue',
        'Other (please specify)',
        'Spoiled/Expired Product',
        'Wrong Nutritional Product',
        'Wrong Patient',
        'Wrong Rate / Amount',
      ],
    },
    {
      generalType: 'Patient ID/Documentation/Consent',
      specificTypes: [
        'Chart Correction',
        'Consent Issue',
        'Delay/Lack of Response to Patient Condition (e.g. not assessed/deterioration not recognized)',
        'Image - Mislabeled/Incorrect',
        'Lost/Missing/Incomplete Documentation',
        'Other (please specify)',
        'Patient Identification Issue',
        'Patient Registration',
        'Patient Registration Issue',
        'Workplace Hazard',
        'Workplace Incident',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Privacy',
      specificTypes: [
        'Access/View Without Authority',
        'Disclosure of PHI Without Consent',
        'Discussed/Displayed/Distributed Information Inappropriately',
        'Improper Destruction',
        'Incorrect Patient Information',
        'Lost/Stolen/Missing Information',
        'Ordering/Requisition Issue',
        'Other (please specify)',
        'Patient Identification Issue',
        'Patient Registration Issue',
        'Policy/Procedure Not Followed',
        'Scanned/Imported to Incorrect Chart',
        'Unprotected/Unguarded Patient Information',
        'Workplace Incident',
      ],
    },
    {
      generalType: 'Provision of Care',
      specificTypes: [
        'Biomed Equipment',
        'Bed Bugs',
        'Delay/Lack of Response to Patient Condition (e.g. not assessed/deterioration not recognized)',
        'Equipment Not Available',
        'Failure to Obtain Appropriate Assistance',
        'Failure to Respond to Request for Service',
        'Home Care patient not seen not found',
        'Inappropriate Discharge',
        'Information Transfer (i.e. handover)',
        'Left Against Medical Advice',
        'Order Completion Delay/Not Done',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Protocol Deviations for Research Protocols',
        'Referral',
        'Referral Issue',
        'Simulation',
        'SPOT (Child/pt worsening condition)',
        'Transport/Transfer',
        'Transport/Transfer Issue',
        'Unanticipated transfer to higher level of care',
        'Unexpected Patient Cardiopulmonary and/or Respiratory Arrest',
        'Unexpected Patient Death',
        'Visitor Policy Issue',
        'Workplace Hazard',
      ],
    },
    {
      generalType: 'Respiratory / Airway Management',
      specificTypes: [
        'Airway Obstructed',
        'Decannulation - unplanned',
        'Extubation - Unplanned',
        'Intubation - Delayed',
        'Intubation - Difficult',
        'Intubation - Failed',
        'Medical Gas Malfunction',
        'Medical Gas- rate/flow/concentration incorrect',
        'Other (please specify)',
        'Respiratory Mgmt - Delayed',
        'Respiratory Mgmt - Inappropriate',
        'Ventilation Issue',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Skin/Tissue',
      specificTypes: [
        'Dehiscence/Evisceration',
        'Injury',
        'Other (please specify)',
        'Pressure Injury',
        'Skin Integrity Problems',
      ],
    },
    {
      generalType: 'Surgery/Procedure',
      specificTypes: [
        'Break in Sterile Technique',
        'Burn',
        'Complications of Anesthesia',
        'Consent Issue',
        'Count Issue',
        'Diagnosis - Delayed',
        'Foreign Object Retained Post Procedure',
        'Implant Issue',
        'Instrumentation/Equipment Issue',
        'Laboratory Equipment/Instrument Issue',
        'Other (please specify)',
        'Patient Identification Issue',
        'Procedure Issue',
        'Specimen Handling/Storage Issue',
        'Sterilization/Disinfection/Reprocessing Issue',
        'Surgical Site Not Marked',
        'Tissue Trauma From Procedure',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Workplace Hazard/Incident',
      specificTypes: [
        // 'Behavioural Issue',
        'Other (please specify)',
        'Workplace Hazard',
        'Workplace Incident',
      ],
    },
    {
      generalType: 'JHSC Workplace Inspection',
      specificTypes: [],
    },
    {
      generalType: 'Local Level Feedback',
      specificTypes: [],
    },
  ];
  public typesFr = [
    {
      generalType: 'Produit sanguin',
      specificTypes: [
        "Problème d'administration de produit sanguin",
        'Problème de livraison de produit sanguin',
        'Produit sanguin gaspillé',
        'Problème de commande',
        'Autre (veuillez préciser)',
        'Problème de processus du laboratoire de transfusion',
        'Réaction transfusionnelle',
      ],
    },
    {
      generalType: 'Produit défectueux/notification de rappel',
      specificTypes: [
        'Problème de produit jetable/à usage unique',
        'Autre (veuillez préciser)',
        'Problème de produit reprocessable',
      ],
    },
    {
      generalType: 'Diagnostic/Traitement',
      specificTypes: [
        'Réaction allergique (non liée aux médicaments)',
        'Retard dans le traitement/résultats',
        "Retard/manque de réponse à l'état du patient (ex. non évalué/détérioration non reconnue)",
        'Diagnostic - Retardé',
        'Problème de diagnostic',
        'Autre (veuillez préciser)',
        'Traitement - Inapproprié',
        'Traitement - Mal exécuté',
        'Arrêt cardio-respiratoire et/ou respiratoire inattendu du patient',
        'Mauvais patient',
      ],
    },
    {
      generalType: 'Examiner et soumettre l incident',
      specificTypes: [
        'Soutien adulte (Maladie/blessure chez un adulte)',
        'Code Bleu (Événement médical mettant la vie en danger)',
        'Code Brun (Déversement dangereux/décontamination)',
        'Code ED (Afflux élevé/acuité élevée aux urgences)',
        'Code Vert (Évacuation)',
        "Code Gris (Défaillance/perte des systèmes d'information/installations)",
        'Code Orange (Catastrophe/Incident de victimes massives)',
        'Code Rose (Réanimation néonatale)',
        'Code Rouge (Incendie)',
        'Code Transfusion (8888)',
        'Code Blanc (Comportement présentant un risque de blessure)',
        'Code Jaune (Patient/enfant disparu/enlevé)',
        'Confinement',
        'Traumatisme pénétrant',
        'Abri sur place',
        'SPOT (État du patient/enfant en détérioration)',
        'STAT',
        'Traumatisme',
      ],
    },
    {
      generalType: 'Equipment/Medical Device',
      specificTypes: [
        'Objet cassé',
        'Déconnecté',
        'Problème de produit jetable/à usage unique',
        'Problème électrique',
        'Équipement - Défectueux',
        'Équipement - Non disponible',
        "Dysfonctionnement de l'équipement",
        'Problème de produit reprocessable',
      ],
    },
    {
      generalType: 'Chute/blessure',
      specificTypes: ['Chute', 'Blessure', 'Autre (veuillez préciser)'],
    },
    {
      generalType: 'Comportement des patients à haut risque',
      specificTypes: [
        'Agression',
        'Fugue/Disparition',
        'Problème de comportement',
        'Objet(s) de contrebande trouvé(s)',
        'Autre (veuillez préciser)',
        'Lié à la contention',
        'Problème lié à la contention',
        'Automutilation',
        'Geste/suicide tenté',
      ],
    },
    {
      generalType: 'Infection',
      specificTypes: [
        'Problème de précautions supplémentaires',
        "Infection/colonisation acquise à l'hôpital",
        'Autre (veuillez préciser)',
        'Exposition du patient - Sang/liquide biologique',
        'Exposition du patient - Autre',
        'Problème de pratiques courantes',
        'Problème de stérilisation/désinfection/retraitement',
      ],
    },
    {
      generalType: 'Dispositif d accès IV/vasculaire',
      specificTypes: [
        'Cathéter ou tube fissuré/endommagé/coupé',
        'Problème de maintenance du bundle CVL',
        'Déconnecté',
        'Déplacé/Excentré',
        'Déplacement/Excentré',
        'Infiltration/Extravasation',
        'Insertion',
        'Tentatives multiples/prélèvement sanguin inutile/tentatives',
        'Occlusion',
        'Autre (veuillez préciser)',
        'Politique/Procédure non suivie',
        'Politique/Procédure non suivie (problème de maintenance du bundle CVL)',
        'Problème de pratiques courantes',
        'Tube non changé',
        'Mauvais lumen accédé',
      ],
    },
    {
      generalType: 'Laboratoire/échantillon',
      specificTypes: [
        'Problème de collecte',
        'Problème de valeur critique',
        'Problème de manipulation/stockage',
        "Problème d'étiquetage/identification",
        "Problème de signature d'étiquetage",
        "Problème d'équipement/instrument de laboratoire",
        'Problème de fournitures de laboratoire',
        'Problème de commande/demande',
        'Autre (veuillez préciser)',
        'Résultat retardé',
        'Résultat incorrect',
        'Résultat non rapporté',
        'Résultat rapporté au mauvais patient',
        "Problème d'enregistrement des échantillons",
        'Problème de réception des échantillons',
        'Problème de transport des échantillons',
      ],
    },
    {
      generalType: 'Ligne/Tube',
      specificTypes: [
        'Bloqué/Occlus',
        'Cassé/Perforé/Coupé',
        'Déconnecté',
        'Interrompu sans ordonnance',
        'Déplacé/Excentré',
        'Insertion',
        'Autre (veuillez préciser)',
        'Politique/Procédure non suivie',
        "Mauvais emplacement d'insertion",
        'Mauvaise taille insérée',
        'Mauvais type de tube inséré',
      ],
    },
    {
      generalType: 'Imagerie médicale',
      specificTypes: [
        'Contraste - Réaction allergique',
        'Contraste - Événement',
        'Contraste - Extravasation',
        'Retard dans le traitement/résultats',
        'Exposition - Sur-exposition',
        'Image - Détériorée/perdue',
        'Image - Mal étiquetée/incorrecte',
        'Problème de sécurité IRM',
        'Problème de commande/demande',
        'Autre (veuillez préciser)',
        'Politique/Procédure non suivie',
        'Résultats - Lecture incorrecte',
        'Test - Commandé, non effectué',
        'Test - Mauvaise procédure',
        'Test - Mauvais côté (G vs D)',
        'Test - Mauvais site',
        'Mauvais patient',
      ],
    },
    {
      generalType: 'Médicament/Solution IV',
      specificTypes: [
        'Réaction indésirable au médicament',
        'Problème de code-barres',
        'Contre-indiqué - Allergie connue',
        'Contre-indication - Allergie/Intolérance connue',
        'Livré à la mauvaise unité',
        'Incompatibilité médicamenteuse',
        'Commandes en double',
        'Produit périmé',
        'Dose supplémentaire',
        'Dose en retard (>1h)',
        'Dose en retard/délai dans le traitement',
        'Réconciliation des médicaments',
        'Dose oubliée',
        'Médicament manquant (non narcotique)',
        'Discrépanecie narcotique',
        'Problème de commande',
        'Autre (veuillez préciser)',
        'Gestion de la douleur insuffisante',
        'Produit endommagé',
        'Intégrité du produit',
        "Problème d'intégrité du produit",
        'Programmation de la pompe',
        'Stockage incorrect/Élimination inadéquate',
        'Surveillance des niveaux de médicaments thérapeutiques',
        'Problème de surveillance des niveaux de médicaments thérapeutiques',
        'Mauvaise concentration',
        'Mauvaise dose',
        'Mauvais médicament',
        'Mauvaise formulation',
        'Mauvais patient',
        'Mauvais taux',
        "Mauvais mode d'administration",
        'Mauvais site',
        'Mauvaise solution',
      ],
    },
    {
      generalType: 'Examen de la morbidité/mortalité',
      specificTypes: ['Morbidité', 'Mortalité'],
    },
    {
      generalType: 'Nutrition',
      specificTypes: [
        'Réaction allergique (non liée aux médicaments)',
        'Alerte allergie non suivie / documentée',
        'Nutrition retardée',
        'Protocole de nutrition / ordonnance non suivie',
        'Portion incorrecte / manquante',
        "Problème d'étiquetage/identification",
        'Directives NPO non suivies',
        'Problème de commande',
        'Autre (veuillez préciser)',
        'Produit gâté/périmé',
        'Mauvais produit nutritionnel',
        'Mauvais patient',
        'Mauvais taux / montant',
      ],
    },
    {
      generalType: 'D du patient/Documentation/Consentement',
      specificTypes: [
        'Correction de dossier',
        'Problème de consentement',
        'Documentation perdue/manquante/incomplète',
        'Autre (veuillez préciser)',
        'Enregistrement du patient',
        "Problème d'enregistrement du patient",
      ],
    },
    {
      generalType: 'Confidentialité',
      specificTypes: [
        'Accès/vue sans autorisation',
        'Divulgation de PHI sans consentement',
        'Information discutée/affichée/distribuée de manière inappropriée',
        'Destruction incorrecte',
        'Informations patient incorrectes',
        'Informations perdues/volées/manquantes',
        'Autre (veuillez préciser)',
        'Politique/Procédure non suivie',
        'Numérisé/importé dans le mauvais dossier',
        'Informations patient non protégées/non gardées',
      ],
    },
    {
      generalType: 'PPrestation de soins',
      specificTypes: [
        'Équipement biomédical',
        'Punaises de lit',
        "Retard/manque de réponse à l'état du patient (par exemple, non évalué/détérioration non reconnue)",
        'Équipement non disponible',
        "Échec d'obtenir l'assistance appropriée",
        'Échec de répondre à la demande de service',
        'Patient en soins à domicile non vu/non trouvé',
        'Sortie inappropriée',
        "Transfert d'informations (c'est-à-dire passation)",
        'Parti contre avis médical',
        'Retard/Non-complétion de la commande',
        'Autre (veuillez préciser)',
        'Politique/Procédure non suivie',
        'Déviations de protocole pour les protocoles de recherche',
        'Référence',
        'Problème de référence',
        'Simulation',
        'Transport/Transfert',
        'Problème de transport/transfert',
        'Transfert imprévu vers un niveau de soins supérieur',
        'Arrêt cardiorespiratoire imprévu chez le patient',
        'Décès imprévu du patient',
        'Problème de politique de visite',
      ],
    },
    {
      generalType: 'Gestion respiratoire/des voies respiratoires',
      specificTypes: [
        'Voies respiratoires obstruées',
        'Décannulation - imprévue',
        'Extubation - imprévue',
        'Intubation - retardée',
        'Intubation - difficile',
        'Intubation - échouée',
        'Dysfonctionnement des gaz médicaux',
        'Gaz médicaux - taux/débit/concentration incorrects',
        'Autre (veuillez préciser)',
        'Gestion respiratoire - retardée',
        'Gestion respiratoire - inappropriée',
        'Problème de ventilation',
        'Mauvais patient',
      ],
    },
    {
      generalType: 'Peau/Tissus',
      specificTypes: [
        'Déhiscence/Eviscération',
        'Blessure',
        'Autre (veuillez préciser)',
        'Lésion de pression',
        "Problèmes d'intégrité cutanée",
      ],
    },
    {
      generalType: 'Chirurgie/procédure',
      specificTypes: [
        'Violation de la technique stérile',
        'Brûlure',
        "Complications de l'anesthésie",
        'Problème de consentement',
        'Problème de comptage',
        'Diagnostic - retardé',
        'Objet étranger retenu après la procédure',
        "Problème d'implant",
        "Problème d'instrumentation/équipement",
        "Problème d'équipement/instrument de laboratoire",
        'Autre (veuillez préciser)',
        "Problème d'identification du patient",
        'Problème de procédure',
        "Problème de manipulation/stockage d'échantillon",
        'Problème de stérilisation/désinfection/retraitement',
        'Site chirurgical non marqué',
        'Traumatisme tissulaire dû à la procédure',
        'Mauvais patient',
      ],
    },
    {
      generalType: 'Risque/incident sur le lieu de travail',
      specificTypes: [
        'Problème comportemental',
        'Autre (veuillez préciser)',
        'Danger au travail',
        'Incident au travail',
      ],
    },
    {
      generalType: 'Milieu de travail du JHSC Inspection',
      specificTypes: [],
    },
    {
      generalType: 'Commentaires au niveau local',
      specificTypes: [],
    },
  ];
  public typesDe = [
    {
      generalType: 'Blutprodukt',
      specificTypes: [
        'Problem bei der Blutproduktverabreichung',
        'Problem bei der Blutproduktlieferung',
        'Blutprodukt verschwendet',
        'Bestellproblem',
        'Anderes (bitte angeben)',
        'Problem im Transfusionslaborprozess',
        'Transfusionsreaktion',
      ],
    },
    {
      generalType: 'Defektes Produkt/Rückrufbenachrichtigung',
      specificTypes: [
        'Problem mit Einweg-/Einmalprodukt',
        'Anderes (bitte angeben)',
        'Problem mit wiederaufbereitbarem Produkt',
      ],
    },
    {
      generalType: 'Diagnose/Behandlung',
      specificTypes: [
        'Allergische Reaktion (nicht medikamentenbedingt)',
        'Verzögerung bei der Behandlung/Ergebnissen',
        'Verzögerung/Mangelnde Reaktion auf den Patientenstatus (z.B. nicht beurteilt/Verschlechterung nicht erkannt)',
        'Diagnose - Verzögert',
        'Diagnoseproblem',
        'Anderes (bitte angeben)',
        'Behandlung - Unangemessen',
        'Behandlung - Falsch durchgeführt',
        'Unerwarteter kardiopulmonaler und/oder respiratorischer Arrest des Patienten',
        'Falscher Patient',
      ],
    },
    {
      generalType: 'Notruf mit Code',
      specificTypes: [
        'Erwachsenenunterstützung (Krankheit/Verletzung bei Erwachsenen)',
        'Code Blau (Lebensbedrohliches medizinisches Ereignis)',
        'Code Braun (Gefährliche Verschüttung/Desinfektion)',
        'Code ED (Hohe Auslastung/Schwere in der Notaufnahme)',
        'Code Grün (Evakuierung)',
        'Code Grau (IS/Einrichtungsfehler/Verlust)',
        'Code Orange (Katastrophe/Massenverletzungsereignis)',
        'Code Pink (Neonatale Reanimation)',
        'Code Rot (Feuer)',
        'Code Transfusion (8888)',
        'Code Weiß (Verhalten mit Risiko für Schäden)',
        'Code Gelb (Vermisster/entführter Patient/Kind)',
        'Lockdown',
        'Penetrierende Traumata',
        'Zuflucht vor Ort',
        'SPOT (Kind/Patient verschlechterte Zustand)',
        'STAT',
        'Trauma',
      ],
    },
    {
      generalType: 'Equipment/Medical Device',
      specificTypes: [
        'Defekter Artikel',
        'Getrennt/Verbindung unterbrochen',
        'Problem mit Einweg-/Einmalprodukt',
        'Elektrisches Problem',
        'Ausrüstung - Defekt',
        'Ausrüstung - Nicht verfügbar',
        'Ausrüstungsstörung',
        'Problem mit wiederaufbereitbarem Produkt',
      ],
    },
    {
      generalType: 'Sturz / Verletzung',
      specificTypes: ['Fall', 'Verletzung', 'Anderes (bitte angeben)'],
    },
    {
      generalType: 'Hochrisikopatientenverhalten',
      specificTypes: [
        'Angriff',
        'AWOL/Vermisst',
        'Verhaltensproblem',
        'Gefundenes Schmuggelgut',
        'Anderes (bitte angeben)',
        'Fixierung bezogen',
        'Fixierungsproblem',
        'Selbstverletzung',
        'Suizidale Geste/Versuch',
      ],
    },
    {
      generalType: 'Infektion',
      specificTypes: [
        'Problem mit zusätzlichen Vorsichtsmaßnahmen',
        'Krankenhausinfektion/Kolonisation',
        'Anderes (bitte angeben)',
        'Patientenexposition - Blut/Körperflüssigkeit',
        'Patientenexposition - Andere',
        'Problem mit routinemäßigen Praktiken',
        'Problem mit Sterilisation/Desinfektion/Wiederaufbereitung',
      ],
    },
    {
      generalType: 'IV/Gefäßzugangsgerät',
      specificTypes: [
        'Katheter oder Schlauch gebrochen/beschädigt/abgetrennt',
        'CVL Wartungsbundle-Problem',
        'Getrennt/Verbindung unterbrochen',
        'Verlagerung/Versetzung',
        'Verlagerung/Versetzt',
        'Infiltration/Extravasation',
        'Einführung',
        'Mehrere Versuche/Unnötige Blutabnahme/Versuche',
        'Verstopfung',
        'Anderes (bitte angeben)',
        'Richtlinie/Verfahren nicht befolgt',
        'Richtlinie/Verfahren nicht befolgt (CVL Wartungsbundle-Problem)',
        'Problem mit routinemäßigen Praktiken',
        'Schlauch nicht gewechselt',
        'Falsches Lumen zugegriffen',
      ],
    },
    {
      generalType: 'Labor/Probe',
      specificTypes: [
        'Sammlungsproblem',
        'Problem mit kritischem Wert',
        'Problem bei der Handhabung/Lagerung',
        'Probleme mit Kennzeichnung/ID',
        'Probleme mit der Signatur der Kennzeichnung',
        'Problem mit Laborgeräten/Instrumenten',
        'Problem mit Laborbedarf',
        'Problem bei der Bestellung/Anforderung',
        'Anderes (bitte angeben)',
        'Ergebnis verzögert',
        'Ergebnis inkorrekt',
        'Ergebnis nicht gemeldet',
        'Ergebnis dem falschen Patienten gemeldet',
        'Problem bei der Probenregistrierung',
        'Problem beim Probenempfang',
        'Problem beim Probentransport',
      ],
    },
    {
      generalType: 'Linie / Rohr',
      specificTypes: [
        'Blockiert/Verstopft',
        'Gebrochen/Perforiert/Abgetrennt',
        'Getrennt/Verbindung unterbrochen',
        'Abgebrochen ohne Bestellung',
        'Verlagerung/Versetzung',
        'Einführung',
        'Anderes (bitte angeben)',
        'Richtlinie/Verfahren nicht befolgt',
        'Falscher Einführungsort',
        'Falsche Größe eingeführt',
        'Falscher Schlauchtyp eingeführt',
      ],
    },
    {
      generalType: 'Medizinische Bildgebung',
      specificTypes: [
        'Kontrast - Allergische Reaktion',
        'Kontrast - Ereignis',
        'Kontrast - Extravasation',
        'Verzögerung bei der Behandlung/Ergebnissen',
        'Exposition - Überbelichtung',
        'Bild - Zerstört/Verloren',
        'Bild - Falsch beschriftet/Ink korrekt',
        'MRI Sicherheitsproblem',
        'Problem bei der Bestellung/Anforderung',
        'Anderes (bitte angeben)',
        'Richtlinie/Verfahren nicht befolgt',
        'Ergebnisse - Falsche Auswertung',
        'Test - Bestellt, aber nicht durchgeführt',
        'Test - Falsches Verfahren',
        'Test - Falsche Seite (links vs. rechts)',
        'Test - Falscher Ort',
        'Falscher Patient',
      ],
    },
    {
      generalType: 'Medikamente/IV-Lösung',
      specificTypes: [
        'Nebenwirkung auf Arzneimittel',
        'Barcode-Problem',
        'Kontraindiziert - Bekannte Allergie',
        'Kontraindikation - Bekannte Allergie/Unverträglichkeit',
        'Falsche Lieferung an die falsche Einheit',
        'Arzneimittelunverträglichkeit',
        'Doppelte Bestellungen',
        'Abgelaufenes Produkt',
        'Zusätzliche Dosis',
        'Verspätete Dosis (>1 Stunde)',
        'Verspätete Dosis/Behandlungsverzögerung',
        'Medikamentenabgleich',
        'Verpasste Dosis',
        'Fehlendes Medikament (nicht-narkotisch)',
        'Narkotische Diskrepanz',
        'Bestellproblem',
        'Anderes (bitte angeben)',
        'Unzureichende Schmerzbehandlung',
        'Produkt beschädigt',
        'Produktintegrität',
        'Problem mit Produktintegrität',
        'Pumpenprogrammierung',
        'Falsche Lagerung/Unzureichende Entsorgung',
        'Therapeutische Arzneimittelspiegelüberwachung',
        'Problem mit therapeutischer Arzneimittelspiegelüberwachung',
        'Falsche Konzentration',
        'Falsche Dosis',
        'Falsches Medikament',
        'Falsche Formulierung',
        'Falscher Patient',
        'Falsche Rate',
        'Falscher Weg',
        'Falscher Ort',
        'Falsche Lösung',
      ],
    },
    {
      generalType: 'berprüfung der Morbidität/Mortalität',
      specificTypes: ['Morbidity', 'Sterblichkeit'],
    },
    {
      generalType: 'Ernährung',
      specificTypes: [
        'Allergische Reaktion (nicht medikamentenbedingt)',
        'Allergiewarnung nicht befolgt/dokumentiert',
        'Verzögerte Ernährung',
        'Ernährungsprotokoll/Bestellung nicht befolgt',
        'Falsche/fehlende Portion',
        'Kennzeichnungs-/ID-Problem',
        'NPO-Richtlinien nicht befolgt',
        'Bestellproblem',
        'Anderes (bitte angeben)',
        'Verdorbenes/abgelaufenes Produkt',
        'Falsches Ernährungsprodukt',
        'Falscher Patient',
        'Falsche Rate/Menge',
      ],
    },
    {
      generalType: 'Patienten-ID/Dokumentation/Einwilligung',
      specificTypes: [
        'Diagrammkorrektur',
        'Einwilligungsproblem',
        'Verlorene/fehlende/unvollständige Dokumentation',
        'Anderes (bitte angeben)',
        'Patientenregistrierung',
        'Problem bei der Patientenregistrierung',
      ],
    },
    {
      generalType: 'Privatsphäre',
      specificTypes: [
        'Zugriff/Ansicht ohne Autorisierung',
        'Offenlegung von PHI ohne Zustimmung',
        'Informationen unangemessen besprochen/angezeigt/verteilt',
        'Unzulässige Zerstörung',
        'Falsche Patienteninformation',
        'Verlorene/gestohlene/fehlende Informationen',
        'Anderes (bitte angeben)',
        'Richtlinie/Verfahren nicht befolgt',
        'In falsches Diagramm gescannt/importiert',
        'Ungeschützte/ungesicherte Patienteninformationen',
      ],
    },
    {
      generalType: 'Bereitstellung von Pflege',
      specificTypes: [
        'Biomedizinische Ausrüstung',
        'Bettwanzen',
        'Verzögerung/Mangel an Reaktion auf den Zustand des Patienten (z. B. nicht beurteilt/ Verschlechterung nicht erkannt)',
        'Ausrüstung nicht verfügbar',
        'Fehler, angemessene Hilfe zu erhalten',
        'Fehler, auf Anfrage nach Service zu reagieren',
        'Home Care-Patient nicht gesehen/nicht gefunden',
        'Unangemessene Entlassung',
        'Informationsübertragung (d.h. Übergabe)',
        'Verlassen gegen ärztlichen Rat',
        'Bestellabschlussverzögerung/nicht durchgeführt',
        'Anderes (bitte angeben)',
        'Richtlinie/Verfahren nicht befolgt',
        'Abweichungen vom Protokoll bei Forschungsprotokollen',
        'Überweisung',
        'Überweisungsproblem',
        'Simulation',
        'Transport/Übertragung',
        'Transport/Übertragungsproblem',
        'Unvorhergesehene Übertragung auf ein höheres Versorgungsniveau',
        'Unerwarteter kardiopulmonaler und/oder respiratorischer Stillstand des Patienten',
        'Unerwarteter Tod des Patienten',
        'Besucherrichtlinienproblem',
      ],
    },
    {
      generalType: 'Atemwegs-/Atemwegsmanagement',
      specificTypes: [
        'Atemweg blockiert',
        'Dekannulation - unvorhergesehen',
        'Extubation - Unvorhergesehen',
        'Intubation - Verzögert',
        'Intubation - Schwierig',
        'Intubation - Fehlgeschlagen',
        'Medizinisches Gas - Fehlfunktion',
        'Medizinisches Gas - Rate/Durchfluss/Konzentration inkorrekt',
        'Anderes (bitte angeben)',
        'Atemmanagement - Verzögert',
        'Atemmanagement - Unangemessen',
        'Beatmungsproblem',
        'Falscher Patient',
      ],
    },
    {
      generalType: 'Haut / Gewebe',
      specificTypes: [
        'Wundaufklaffen/Eviszeration',
        'Verletzung',
        'Anderes (bitte angeben)',
        'Druckverletzung',
        'Hautintegritätsprobleme',
      ],
    },
    {
      generalType: 'Operation/Eingriff',
      specificTypes: [
        'Verstoß gegen sterile Technik',
        'Verbrennung',
        'Komplikationen der Anästhesie',
        'Einwilligungsproblem',
        'Zählproblem',
        'Diagnose - Verzögert',
        'Fremdkörper nach dem Eingriff behalten',
        'Implantatproblem',
        'Instrumenten-/Ausrüstungsproblem',
        'Laborgeräte-/Instrumentenproblem',
        'Anderes (bitte angeben)',
        'Problem mit der Patientenidentifikation',
        'Verfahrensproblem',
        'Problem mit der Handhabung/Lagerung von Proben',
        'Problem mit Sterilisation/Desinfektion/Wiederaufbereitung',
        'Chirurgische Stelle nicht markiert',
        'Gewebetrauma durch den Eingriff',
        'Falscher Patient',
      ],
    },
    {
      generalType: 'Gefahr/Vorfall am Arbeitsplatz',
      specificTypes: [
        'Verhaltensproblem',
        'Anderes (bitte angeben)',
        'Arbeitsplatzgefahr',
        'Arbeitsunfall',
      ],
    },
    {
      generalType: 'JHSC-Arbeitsplatzinspektion',
      specificTypes: [],
    },
    {
      generalType: 'Feedback auf lokaler Ebene',
      specificTypes: [],
    },
  ];
  public questionnaires = [];

  public specificTypes = [];
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}
  public flag: boolean = false;
  ParentRegionData: any;
  ParentRegionDataAPi: any;
  ngOnInit(): void {
    setTimeout(() => {
      this.questionnaireLanguage = this.bsModalRef?.content?.modalData?.language
        ? this.bsModalRef?.content?.modalData?.language
        : 'en';
      if (this.questionnaireLanguage == 'en') {
        this.types = this.typesEn;
      } else if (this.questionnaireLanguage == 'de') {
        this.types = this.typesDe;
      } else {
        this.types = this.typesFr;
      }
      this.getQuestionnaireList();
    });
  }
  onGeneralType(event) {
    this.specificTypes = event.specificTypes;

    console.log(this.specificTypes);
  }
  getQuestionnaireList() {
    let dataObj = {
      language: this.questionnaireLanguage,
    };
    this.commonService
      .callApi(this.callAPIConstants.AllQuestionnaireList, dataObj, 'post')
      .then((success) => {
        if (success.status == 1) {
          console.log(success);
          this.questionnaires = success.listing;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }
  /****************************************************************************
  @Purpose     : Add/Update Project
  @Parameter   : form, projectData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (form.valid) {
      form.value.language = this.questionnaireLanguage;
      this.commonService
        .callApi(
          this.callAPIConstants.Duplicatequestionnaire,
          form.value,
          'post'
        )
        .then((success) => {
          if (success.status == 1) {
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', success.message);
          } else {
            this.showErrorService.popToast('error', success.message);
            this.router.navigate([this.URLConstants.QUESTION]);
          }
        });
      this.bsModalRef.hide();
    } else {
      this.submitted = true;
    }
  }
}
