<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{ bsModalRef?.content?.modalData?.blogsId ? 'Edit blogs' : 'Add blogs' }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, BlogsData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label>blogs Title<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Title "
              name="title"
              id="name"
              #title="ngModel"
              [(ngModel)]="BlogsData.title"
              required
            />
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || title.touched) && title.invalid"
            >
              Enter Title
            </div>
          </div>

          <div class="form-group">
            <label>Author<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter author"
              name="author"
              id="name"
              #author="ngModel"
              [(ngModel)]="BlogsData.author"
              required
            />
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || author.touched) && author.invalid"
            >
              Enter Author
            </div>
          </div>

          <div class="form-group">
            <label>Description<sup class="bx bxs-star danger"></sup></label>

            <textarea
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Description "
              name="description"
              id="name"
              rows="5"
              #description="ngModel"
              [(ngModel)]="BlogsData.description"
              required
            ></textarea>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || description.touched) && description.invalid"
            >
              Enter Description
            </div>
          </div>

          <div class="form-group">
            <label>Body template</label>
            <angular-editor
              id="blogTemplate"
              [(ngModel)]="BlogsData.blogTemplate"
              #angulareditor
              #blogTemplate="ngModel"
              [config]="editorConfig"
              name="blogTemplate"
            ></angular-editor>
          </div>

          <div class="form-group">
            <label>type<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="type"
              [items]="typeList"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #type="ngModel"
              [(ngModel)]="BlogsData.type"
              [searchable]="true"
              autocomplete="type"
              placeholder="Enter type "
              required
              disabled="{{
                bsModalRef?.content?.modalData?.blogsId ? true : false
              }}"
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || type.touched) && type.invalid"
            >
              Select type
            </div>
          </div>

          <div *ngIf="BlogsData.type == 'podcast'" class="form-group">
            <label>Link<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Link "
              name="link"
              id="name"
              #link="ngModel"
              [(ngModel)]="BlogsData.link"
              required
            />
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || link.touched) && link.invalid"
            >
              Enter Link
            </div>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="BlogsData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="form-group custom-upload mt-4 mb-3">
            <span class="bx bx-upload icon-pink-color d-block mb-2"></span>
            <p class="text-light-black">
              <strong class="text-pink">Browse</strong> file or Drag & drop
              image here.
            </p>
            <label class="sr-only" for="imageupload">
              file or Drag & drop image here.t</label
            >
            <input
              type="file"
              class="form-control-file"
              id="imageupload"
              (change)="onFileSelected($event, 'incident')"
            />
          </div>
          <div class="upload-img-list d-flex flex-wrap flex-md-row mt-2">
            <ng-container *ngIf="userPhotoAndVideoData !== ''">
              <div class="uploaded-img mr-3 mb-3">
                <span
                  class="delete-item icon-close"
                  (click)="removeData(userPhotoAndVideoData, 'incident')"
                ></span>
                <picture style="cursor: pointer">
                  <img
                    width="76"
                    height="76"
                    [src]="userPhotoAndVideoData"
                    alt="upload Image"
                    #img
                    (click)="
                      showErrorService.showMediaModal(userPhotoAndVideoData)
                    "
                  />
                </picture>
              </div>
            </ng-container>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
