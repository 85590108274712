<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">Add a comment</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, commentData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <div
              class=""
              [ngClass]="submitted || comment.touched ? 'has-danger' : ''"
            >
              <textarea
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter comment"
                name="comment"
                id="comment"
                rows="5"
                #comment="ngModel"
                [(ngModel)]="commentData.name"
                required
              ></textarea>

              <div *ngIf="comment.errors && comment.touched">
                <small
                  class="text-danger"
                  *ngIf="comment.errors.required && comment.touched"
                >
                  Please enter a comment</small
                >
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
