<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">Reporting Users</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <div class="report-modal modal-body">
      <ng-container *ngFor="let item of Data">
        <div class="user-details" style="margin-bottom: 15px">
          <div class="image-user" style="width: 20%">
            <img
              style="border-radius: 50%; width: 100%"
              [src]="
                item.user ? item.user?.photo : 'assets/images/user-img.svg'
              "
              alt="no img"
            />
          </div>
          <div class="name" style="padding: 5px 15px">
            <p>{{ item.user?.username }}</p>
            {{ item.user?.emailId }}
          </div>
          <!-- <div class="user-info ">
                            <div class="name" style="padding: 5px 15px;"> 
                               <p>{{item.user?.username}}</p> 
                                  {{item.user?.emailId}}
                            </div>
                           
                        </div> -->
          <div>
            {{ item?.reason }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
