import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, Subject, concat } from 'rxjs';
import {
  merge,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map,
  startWith,
} from 'rxjs/operators';

// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-addsub-region',
  templateUrl: './addsub-region.component.html',
  styleUrls: ['./addsub-region.component.scss'],
})
export class AddsubRegionComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  //observable assign
  public domainList: Observable<any>;
  public domainList1: Observable<any>;
  public internalAssigneDataSource = new Subject<any>();
  public internalAssigneLoader: boolean = false;
  public focus$ = new Subject<string>();
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  subRegionData: any = {};
  buttonName: any = 'Add';
  devChecklistId: any;
  modalData: any;
  notFoundText1: any;
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}
  public flag: boolean = false;
  ParentRegionData: any;
  ParentRegionDataAPi: any;
  ngOnInit(): void {
    this.loadRegion();
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData.subRegionId) {
        this.flag = true;
        this.buttonName = 'Update';
        this.subRegionData.name = this.bsModalRef?.content?.modalData?.name;
        this.subRegionData.isActive =
          this.bsModalRef?.content?.modalData?.isActive;
        this.subRegionData.parentRegion =
          this.bsModalRef?.content?.modalData?.parentRegion;
        this.subRegionData.subRegionId =
          this.bsModalRef?.content?.modalData?.subRegionId;
        this.getsubRegionData(this.subRegionData.subRegionId);
      }
    });
  }
  onRegion(event) {
    this.ParentRegionData = event._id;
  }
  getsubRegionData(data) {
    let dataObj = data;
    this.commonService
      .callApi(
        this.callAPIConstants.GetSubRegionById,
        { subRegionId: dataObj },
        'post'
      )
      .then((success) => {
        if (success.status == 1) {
          this.ParentRegionDataAPi = success.data.parentRegion._id;
        } else {
          this.showErrorService.popToast('error', success.message);
          this.router.navigate([this.URLConstants.SUB_REGION]);
        }
      });
  }

  loadRegion() {
    this.getRegionSearchList('', false);
    this.domainList = concat(
      of([]),
      this.internalAssigneDataSource.pipe(
        startWith(this.subRegionData),
        debounceTime(100),
        merge(this.focus$),
        distinctUntilChanged(),
        switchMap((term) => this.getRegionSearchList(term, false)),
        map((response) => {
          return response;
        })
      )
    );
  }

  getRegionSearchList(value?, allow?): Observable<any[]> {
    this.internalAssigneLoader = true;
    var searchText = value;
    let items = [];
    return this.commonService
      .callApiObservable(this.callAPIConstants.SearchRegion, {
        page: 1,
        pagesize: 20,
        filter: { searchText: searchText ? searchText : '' },
      })
      .pipe(
        catchError(() => of({ items: [] })),
        map((success: any) => {
          if (success['status'] == 1) {
            this.notFoundText1 = 'No Data Found';
            items = success.data.listing.filter(
              (item) => item.industry !== 'aviation'
            );
            this.internalAssigneLoader = false;
          } else {
            this.showErrorService.popToast('error', success['message']);
          }
          return items ? items : [];
        })
      );
  }

  /****************************************************************************
  @Purpose     : Add/Update Project
  @Parameter   : form, projectData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.subRegionData.subRegionId) {
      let dataObj = {
        subRegionId: this.subRegionData.subRegionId,
        name: form.value.name,
        isActive: form.value.isActive,
        parentRegion: this.ParentRegionData
          ? this.ParentRegionData
          : this.ParentRegionDataAPi,
      };
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.EditSubRegion, dataObj, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.SUB_REGION]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.AddSubRegion, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.SUB_REGION]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
