<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{ bsModalRef?.content?.modalData?.newsId ? 'Edit News' : 'Add News' }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, NewsData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label>News Title<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Title "
              name="newsTitle"
              id="name"
              #newsTitle="ngModel"
              [(ngModel)]="NewsData.newsTitle"
              required
            />
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || newsTitle.touched) && newsTitle.invalid"
            >
              Enter Title
            </div>
          </div>

          <div class="form-group">
            <label>Link<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Link "
              name="link"
              id="name"
              #link="ngModel"
              [(ngModel)]="NewsData.link"
              required
            />
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || link.touched) && link.invalid"
            >
              Enter Link
            </div>
          </div>

          <div class="form-group">
            <label>Description<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Description "
              name="description"
              id="name"
              #description="ngModel"
              [(ngModel)]="NewsData.description"
              required
            />
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || description.touched) && description.invalid"
            >
              Enter Description
            </div>
          </div>

          <div class="form-group">
            <label>type<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="type"
              [items]="typeList"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #type="ngModel"
              [(ngModel)]="NewsData.type"
              [searchable]="true"
              autocomplete="type"
              placeholder="Enter type "
              required
              disabled="{{
                bsModalRef?.content?.modalData?.newsId ? true : false
              }}"
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || type.touched) && type.invalid"
            >
              Select type
            </div>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="NewsData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
