import { Component, OnInit } from '@angular/core';

// services
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { CommonService } from 'src/app/common/service/common.service';
@Component({
  selector: 'app-faqs-category',
  templateUrl: './faqs-category.component.html',
  styleUrls: ['./faqs-category.component.scss'],
})
export class FaqsCategoryComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  // faqsList: any = {};
  faqsCat: any = {};
  public id: any;
  faqsCategory_id: any;
  faqsListing_id: any;
  category: any = {};
  public APIparameters = {};
  public submitted = false;
  categoryOpenModel = false;
  constructor(
    public showErrorService: ShowErrorService,
    public testfaqBsModalRef: BsModalRef,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      if (
        this.testfaqBsModalRef.content.modalData.type === 'edit_faqs_category'
      ) {
        this.faqsCategory_id =
          this.testfaqBsModalRef.content.modalData.faqsCategory_id;
        this.commonService
          .callApi(
            this.callAPIConstants.FaqsCategoryDetails + this.faqsCategory_id,
            '',
            'get',
            false,
            false
          )
          .then((res) => {
            if (res.status === 1) {
              this.faqsCat = res.data;
            }
          });
      }
    });
  }

  /****************************************************************************
  @Purpose     : Add/Update FAQscategory
  @Parameter   : form, faqsCat
  @Return      : NA
  /****************************************************************************/
  createUpdate(form, faqsCat) {
    if (this.faqsCategory_id) {
      faqsCat['faqCategoryId'] = this.faqsCategory_id;
    }
    if (form.valid) {
      this.commonService
        .callApi(
          this.callAPIConstants.AddUpdateFaqsCategory,
          faqsCat,
          'post',
          false,
          false,
          false
        )
        .then((res) => {
          this.testfaqBsModalRef.hide();
          if (res.status === 1) {
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', res.message);
          } else {
            this.showErrorService.popToast('error', res.message);
          }
        });
    } else {
      this.submitted = true;
    }
  }
  /****************************************************************************/
}
