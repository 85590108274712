<div class="page-wrap">
  <!-- START : Header -->
  <app-header></app-header>
  <!-- END : Header -->
  <div class="admin-wrapper">
    <!-- START : Sidebar -->

    <app-sidebar id="sidebar" class="sidebar-close"></app-sidebar>
    <div class="overlay"></div>

    <!-- END : Sidebar -->

    <div class="admin-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!-- START : Footer -->
<!-- <footer class="page-footer">
    <div style="width: 100%;" class="text-center">Copyright &copy; {{currentYear}}. All rights reserved.</div>
</footer> -->
<!-- END : Footer -->
<!-- END : Footer -->
<!-- END : Footer -->
