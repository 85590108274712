<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <div class="icon mr-2"><span class="bx bxs-plus-circle"></span></div>
    <h5 class="mb-0">{{ bsModalRef?.content?.modalData?.title }}</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label>CATEGORY<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || category.touched) && category.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter category"
                name="category"
                #category="ngModel"
                minlength="2"
                [(ngModel)]="blogCat.blogCategoryTitle"
                required
              />
              <div
                class="error-block text-left"
                *ngIf="(submitted || category.touched) && category.invalid"
              >
                {{ showErrorService.showError('category', category.errors) }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>DESCRIPTION<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || description.touched) && description.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <textarea
                autocomplete="off"
                class="form-control"
                rows="4"
                type="text"
                placeholder="description"
                name="description"
                #description="ngModel"
                minlength="20"
                [(ngModel)]="blogCat.blogCategoryDescription"
                required
              ></textarea>

              <div
                class="error-block text-left"
                *ngIf="
                  (submitted || description.touched) && description.invalid
                "
              >
                {{
                  showErrorService.showError('description', description.errors)
                }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button
              type="submit"
              class="btn btn-primary mr-2"
              (click)="createUpdate(form, blogCat)"
            >
              {{
                bsModalRef?.content?.modalData?.title == 'Update Blog Category'
                  ? 'Update'
                  : 'Save'
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
