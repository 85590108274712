<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">Duplicate Questionnaire</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, questionnaireData)">
      <div class="card-body">
        <div class="container">
          <div class="form-details">
            <div class="form-group">
              <label>General Type<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="types"
                [clearable]="true"
                bindLabel="generalType"
                bindValue="generalType"
                [clearable]="true"
                [(ngModel)]="questionnaireData.generalType"
                name="generalType"
                #generalType="ngModel"
                required
                [value]="questionnaireData.generalType"
                [searchable]="true"
                placeholder="Select General Type "
                autocomplete="generalType"
                (change)="onGeneralType($event)"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || generalType.touched) && generalType.invalid
                "
              >
                General Type
              </div>
            </div>

            <div class="form-group">
              <label>Specific Type<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="specificTypes"
                [clearable]="true"
                bindLabel=""
                [(ngModel)]="questionnaireData.specificType"
                required
                name="specificType"
                #specificType="ngModel"
                [value]="questionnaireData.specificType"
                [searchable]="true"
                placeholder="Select Specific Type"
                autocomplete="specificType"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || specificType.touched) && specificType.invalid
                "
              >
                Select specific type
              </div>
            </div>
          </div>
          <div class="form-group">
            <label
              >Unique Section Name<sup class="bx bxs-star danger"></sup
            ></label>
            <div
              class=""
              [ngClass]="
                (submitted || sectionName.touched) && sectionName.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter unique section name "
                name="sectionName"
                id="sectionName"
                #sectionName="ngModel"
                [(ngModel)]="questionnaireData.sectionName"
                required
              />
              <div
                class="error-block text-left"
                *ngIf="
                  (submitted || sectionName.touched) && sectionName.invalid
                "
              >
                Enter section name
              </div>
            </div>
          </div>

          <div class="form-group">
            <label
              >Questionnaire to duplicate<sup class="bx bxs-star danger"></sup
            ></label>
            <ng-select
              class="text-input"
              [items]="questionnaires"
              [clearable]="true"
              bindLabel="generalType"
              bindValue="_id"
              [clearable]="true"
              [(ngModel)]="questionnaireData.questionnaire"
              name="questionnaire"
              #generalType="ngModel"
              required
              [value]="questionnaireData.questionnaire"
              [searchable]="true"
              placeholder="Select General Type "
              autocomplete="questionnaire"
              (change)="onGeneralType($event)"
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || generalType.touched) && generalType.invalid"
            >
              General Type
            </div>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>
            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="questionnaireData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
