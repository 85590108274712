<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <div class="icon mr-2"><span class="bx bxs-plus-circle"></span></div>
    <h5 class="mb-0">{{ testfaqBsModalRef?.content?.modalData?.title }}</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="testfaqBsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm">
      <div class="card-body">
        <div class="container">
          <!--start FAQs category -->
          <div class="form-group">
            <label>ADD CATEGORY<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || faqsCategory.touched) && faqsCategory.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Add category"
                name="faqsCategory"
                #faqsCategory="ngModel"
                [(ngModel)]="faqsCat.faqCategoryName"
                required
              />

              <div
                class="error-block text-left"
                *ngIf="
                  (submitted || faqsCategory.touched) && faqsCategory.invalid
                "
              >
                {{
                  showErrorService.showError(
                    'faqsCategory',
                    faqsCategory.errors
                  )
                }}
              </div>
            </div>
          </div>
          <!-- end FAQs category -->

          <div class="d-flex align-items-center justify-content-end">
            <button
              type="submit"
              class="btn btn-primary mr-2"
              (click)="createUpdate(form, faqsCat)"
            >
              {{
                testfaqBsModalRef?.content?.modalData?.title ==
                'Update Blog
                            Category'
                  ? 'Update'
                  : 'Save'
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
