<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">Create QR Campaign</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, qrData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label
              >QR Campaign Name<sup class="bx bxs-star danger"></sup
            ></label>
            <div
              class=""
              [ngClass]="submitted || name.touched ? 'has-danger' : ''"
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter QR Campaign Name "
                name="name"
                id="name"
                #name="ngModel"
                [(ngModel)]="qrData.name"
                required
              />
              <div *ngIf="name.errors && name.touched">
                <small
                  class="text-danger"
                  *ngIf="name.errors.required && name.touched"
                >
                  Name is required</small
                >
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>URL<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="submitted || url.touched ? 'has-danger' : ''"
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter QR Campaign Name "
                name="url"
                id="url"
                #url="ngModel"
                [(ngModel)]="qrData.url"
                required
              />
              <div *ngIf="url.errors && url.touched">
                <small
                  class="text-danger"
                  *ngIf="url.errors.required && url.touched"
                >
                  URL is required</small
                >
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
