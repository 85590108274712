import { Component, OnInit } from '@angular/core';
// services
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { CommonService } from 'src/app/common/service/common.service';
import { FaqsCategoryComponent } from '../faqs-category/faqs-category.component';
@Component({
  selector: 'app-faqs-list-category',
  templateUrl: './faqs-list-category.component.html',
  styleUrls: ['./faqs-list-category.component.scss'],
})
export class FaqsListCategoryComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  faqsList: any = {};
  // faqsCat:any = {};
  public id: any;
  faqsCategory_id: any;
  faqsListing_id: any;
  category = [];
  public APIparameters = {};
  public submitted = false;
  constructor(
    public showErrorService: ShowErrorService,
    public testbsModalRef: BsModalRef,
    public faqBsModalRef: BsModalRef,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    private modalService: BsModalService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      if (this.testbsModalRef.content.modalData.type === 'edit_faqs_listing') {
        this.faqsListing_id = this.testbsModalRef.content.modalData.faqs_id;
        this.commonService
          .callApi(
            this.callAPIConstants.FaqsDetails + this.faqsListing_id,
            '',
            'get',
            false,
            false
          )
          .then((res) => {
            if (res.status === 1) {
              this.faqsList = res.data;
            }
          });
      }
    });

    /****************************************************************************
  @Purpose     : Get the FAQsCategory
  @Parameter   : NA
  @Return      : faqsCategorylist
  /****************************************************************************/
    this.APIparameters = { key: 'faqsCategoryListing', page: 1, pagesize: 10 };
    this.commonService
      .callApi(
        this.callAPIConstants.FaqsCategoryListing,
        this.APIparameters,
        'post',
        false,
        false
      )
      .then((success) => {
        this.category = success.data.listing;
      });
    /****************************************************************************/
  }

  /****************************************************************************
  @Purpose     : open faqsCategory model
  @Parameter   : NA
  @Return      : NA
  /****************************************************************************/
  categoryOpen() {
    this.faqBsModalRef = this.modalService.show(FaqsCategoryComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        modalData: {
          type: 'Add_FAQs_category',
          title: 'Add FAQs Category',
        },
      },
    });
  }

  /****************************************************************************
  @Purpose     : Add/Update FAQsList
  @Parameter   : form, faqsList
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, faqsList) {
    if (this.faqsListing_id) {
      faqsList['faqId'] = this.faqsListing_id;
    }
    if (form.valid) {
      this.commonService
        .callApi(
          this.callAPIConstants.FaqsAddorUpdate,
          faqsList,
          'post',
          false,
          false,
          false
        )
        .then((res) => {
          this.testbsModalRef.hide();
          if (res.status === 1) {
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', res.message);
          } else {
            this.showErrorService.popToast('error', res.message);
          }
        });
    } else {
      this.submitted = true;
    }
  }
  /****************************************************************************/
}
