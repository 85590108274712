<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{ bsModalRef?.content?.modalData?.emailId ? 'Edit Email' : 'Add Email' }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, emailData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label>Email<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || email.touched) && email.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="email"
                placeholder="Enter Email "
                name="email"
                id="email"
                #email="ngModel"
                [(ngModel)]="emailData.email"
                required
                [email]="true"
              />
              <div *ngIf="email.errors && (email.invalid || email.touched)">
                <small
                  class="text-danger"
                  *ngIf="email.errors.required && email.touched"
                >
                  Email is required</small
                >
                <small class="text-danger" *ngIf="email.errors?.email"
                  >Please provide a valid email address</small
                >
              </div>
            </div>
          </div>

          <!-- <div *ngIf="flag" class="form-details">
            <div class="form-group">
              <label>Industry<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                name="industry"
                [items]="industryArr"
                [clearable]="true"
                bindValue="value"
                bindLabel="name"
                #industry="ngModel"
                [(ngModel)]="this.emailData.industry"
                [searchable]="true"
                autocomplete="industry"
                placeholder="Select Industry "
                required
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || industry.touched) && industry.invalid"
              >
                Select industry
              </div>
            </div>
          </div>

          <div *ngIf="!flag" class="form-details">
            <div class="form-group">
              <label>Industry<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                name="industry"
                [items]="industryArr"
                [clearable]="true"
                bindValue="value"
                bindLabel="name"
                #industry="ngModel"
                [(ngModel)]="this.emailData.industry"
                [searchable]="true"
                autocomplete="industry"
                placeholder="Select Industry "
                required
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || industry.touched) && industry.invalid"
              >
                Select industry
              </div>
            </div>
          </div> -->

          <div *ngIf="flag" class="form-details">
            <div class="form-group">
              <label>Email Tag<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="EmailTagList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.emailData.emailTag"
                name="emailTag"
                #emailTag="ngModel"
                required
                (change)="onchange($event)"
                [searchable]="true"
                placeholder="Select Email Tag "
                autocomplete="emailTag"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || emailTag.touched) && emailTag.invalid"
              >
                Select email tag
              </div>
            </div>
          </div>
          <div *ngIf="!flag" class="form-details">
            <div class="form-group">
              <label>Email Tag<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="EmailTagList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.emailData.emailTag"
                name="emailTag"
                #emailTag="ngModel"
                required
                (change)="onchange($event)"
                bindValue="_id"
                [searchable]="true"
                placeholder="Select Email Tag "
                autocomplete="emailTag"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || emailTag.touched) && emailTag.invalid"
              >
                Select email tag
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Active<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="emailData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select active
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
