import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-add-qr',
  templateUrl: './add-qr.component.html',
  styleUrls: ['./add-qr.component.scss'],
})
export class AddQrComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  base64Image: any;
  qrData: any = {};
  buttonName: any = 'Add';

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}
  ngOnInit(): void {}

  /****************************************************************************
  @Purpose     : Add QR
  @Parameter   : form, qrData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (form.valid) {
      this.commonService
        .callApi(this.callAPIConstants.AddQr, form.value, 'post')
        .then((success) => {
          if (success.status == 1) {
            this.downloadImage(success.data.url, success.data.name);
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', success.message);
          } else {
            this.showErrorService.popToast('error', success.message);
            this.router.navigate([this.URLConstants.EMAILS]);
          }
        });
      this.bsModalRef.hide();
    } else {
      this.submitted = true;
    }
  }

  downloadImage(imageUrl, fileName) {
    this.getBase64ImageFromURL(imageUrl).subscribe((base64data) => {
      this.base64Image = 'data:image/jpg;base64,' + base64data;
      // save image to disk
      var link = document.createElement('a');

      document.body.appendChild(link); // for Firefox

      link.setAttribute('href', this.base64Image);
      link.setAttribute('download', fileName + '.jpeg');
      link.click();
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL('image/png');

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }
}
