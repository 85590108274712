import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: [],
})
export class TemplateDetailsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
