<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{
        bsModalRef?.content?.modalData?.name
          ? 'Edit Place of Service'
          : 'Add Place of Service'
      }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, regionData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label
              >Place of Service<sup class="bx bxs-star danger"></sup
            ></label>
            <div
              class=""
              [ngClass]="
                (submitted || name.touched) && name.invalid ? 'has-danger' : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter Place of Service "
                name="name"
                id="name"
                #name="ngModel"
                [(ngModel)]="regionData.name"
                required
              />
              <div
                class="error-block text-left"
                *ngIf="(submitted || name.touched) && name.invalid"
              >
                Enter Place of Service
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Country<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="country"
              [items]="countries"
              [clearable]="true"
              bindValue="name"
              bindLabel="name"
              #country="ngModel"
              [(ngModel)]="regionData.country"
              [searchable]="true"
              autocomplete="country"
              placeholder="Enter country "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || country.touched) && country.invalid"
            >
              Select country
            </div>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="regionData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
