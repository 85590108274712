import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';

import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

// routing
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// RECOMMENDED
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Common
import { CommonService } from './common/service/common.service';
import { CanAuthActivate, CanLoginActivate } from './common/service/auth-guard';
import { ErrorMessages } from './common/service/errorMessages';

// plugins
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
// component
import { AppComponent } from './app.component';
import { SetPasswordComponent } from './public/set-password/set-password.component';
import { LoginComponent } from './public/login/login.component';
import { HeaderTitleService } from './common/service/header-title.service';

@NgModule({
  bootstrap: [AppComponent],

  declarations: [AppComponent, SetPasswordComponent, LoginComponent],

  imports: [
    SharedModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    ChartModule,
    HighchartsChartModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
  ],

  exports: [],
  providers: [
    CanLoginActivate,
    CommonService,
    CanAuthActivate,
    BsModalRef,
    BsDropdownDirective,
    ErrorMessages,
    HeaderTitleService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
