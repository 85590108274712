import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-delete-comment',
  templateUrl: './delete-comment.component.html',
  styleUrls: ['./delete-comment.component.scss'],
})
export class DeleteCommentComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  Form: FormGroup;
  public previousComment: any = '';
  public commentId: String = '';
  public incidentId: String = '';
  public adminComment: String = '';
  public author: any = {};

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    private fb: FormBuilder,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.Form = this.fb.group({
      adminComment: [''],
    });
    setTimeout(() => {
      this.incidentId = !_.isEmpty(
        this.bsModalRef?.content?.modalData.data.postId
      )
        ? this.bsModalRef?.content?.modalData.data.postId._id
        : '';
      this.commentId = this.bsModalRef?.content?.modalData.data._id;
      this.author = !_.isEmpty(this.bsModalRef?.content?.modalData.data.author)
        ? this.bsModalRef?.content?.modalData.data.author
        : {};
    });
  }

  saveComment() {
    this.adminComment = this.Form.value.adminComment;
    this.commonService
      .callApi(
        this.callAPIConstants.DeleteComment,
        {
          incidentId: this.incidentId,
          commentId: this.commentId,
          adminComment: this.adminComment,
          author: this.author,
        },
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([URLConstants.COMMENTS]);
          this.bsModalRef.hide();
        } else {
          this.bsModalRef.hide();
          this.showErrorService.popToast('error', success.message);
        }
      });
  }
}
