import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss'],
})
export class AddEmailComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  Regionlist: any = {};
  taglist = [
    { name: 'Enable', value: true },
    { name: 'Disable', value: false },
  ];
  emailData: any = {};
  buttonName: any = 'Add';
  devChecklistId: any;
  public industryArr = ['aviation', 'healthcare'];

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}
  public flag: boolean = false;
  ngOnInit(): void {
    this.getEmailTagList();
    this.emailData.industry = this.localStorageService.getToken('industry');
    setTimeout(() => {
      console.log(this.bsModalRef?.content?.modalData.emailId);
      if (this.bsModalRef?.content?.modalData.emailId) {
        this.flag = true;
        this.buttonName = 'Update';
        this.emailData.emailId = this.bsModalRef?.content?.modalData?.emailId;
        this.emailData.emailTag = this.bsModalRef?.content?.modalData?.emailTag;
        this.emailData.industry = this.bsModalRef?.content?.modalData?.industry;
        this.getEmailData(this.bsModalRef?.content?.modalData?.emailId);
        this.emailData.email = this.bsModalRef?.content?.modalData?.email;
        this.emailData.isActive = this.bsModalRef?.content?.modalData?.isActive;
      }
    });
  }
  /****************************************************************************
  @Purpose     : GetEmailTag detail
  @Parameter   : data
  @Return      : NA
  /****************************************************************************/
  EmailTagList: any = [];
  getEmailTagList() {
    this.commonService
      .callApi(this.callAPIConstants.GetEmailTags, false, 'get')
      .then((success) => {
        this.EmailTagList = success.data.map((element) => {
          if (element != null) {
            return element;
          }
        });
      });
  }
  /****************************************************************************
  @Purpose     : Get Event data
  @Parameter   : Event
  @Return      : NA
  /****************************************************************************/
  emailTagId: any;
  onchange(event) {
    this.emailTagId = event._id;
  }
  emailTagId1: any;
  /****************************************************************************
  @Purpose     : Get email tag data
  @Parameter   :  Data -id
  @Return      : NA
  /****************************************************************************/
  getEmailData(data) {
    this.commonService
      .callApi(
        this.callAPIConstants.GetEmailById + data,
        { emailId: data },
        'post'
      )
      .then((success) => {
        if (success.status == 1) {
          this.emailTagId1 = success.data.emailTag._id;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }
  /****************************************************************************
  @Purpose     : Add/Update Domain
  @Parameter   : form, emailData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.emailData.emailId) {
      let dataObj = {
        emailId: this.emailData.emailId,
        email: form.value.email,
        emailTag: this.emailTagId ? this.emailTagId : this.emailTagId1,
        isActive: form.value.isActive,
        industry: this.emailData.industry,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditEmailById + this.emailData.emailId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.EMAILS]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.AddEmail,
            { industry: this.emailData.industry, ...form.value },
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.EMAILS]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
