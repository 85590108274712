import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// constatns
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

// service
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { Observable, of, Subject, concat } from 'rxjs';

import * as _ from 'lodash';
import { Location } from '@angular/common';
@Component({
  selector: 'app-add-solution',
  templateUrl: './add-solution.component.html',
  styleUrls: ['./add-solution.component.scss'],
})
export class AddSolutionComponent implements OnInit {
  public internalAssigneList: Observable<any>;
  notFoundText1: any;
  public technologyList: Observable<any>;
  public internalAssigneLoader: boolean = false;
  public internalAssigneDataSource = new Subject<any>();
  public focus$ = new Subject<string>();
  solutionData: any = {};
  imageSrc: string;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  uploadImageUrl = environment.uploadImgUrl;
  solutionsId: string;
  public isCollapsed = false;
  public incidentTotalData: any = {};
  public tagListArray: Array<any> = [];
  Flag: boolean = true;
  submitted: boolean = false;
  postId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    public bsModalRef: BsModalRef,
    public commonService: CommonService,
    private fb: FormBuilder,
    public modalService: BsModalService,
    public showErrorService: ShowErrorService,
    private router: Router,
    private location: Location,
    private localStorageService: LocalStorageService,
    public broadCasterService: BroadcasterService
  ) {
    this.myForm = this.fb.group({
      title: ['', [Validators.minLength(3)]],
      description: [''],
      tags: [''],
      media: [''],
    });
    this.activatedRoute.params.subscribe((params) => {
      this.solutionsId = params.id;
    });
  }

  /****************************************************************************
   @Purpose     : GetIncidentDefaultData
   @Parameter   : Na
   @Return      : NA
   /****************************************************************************/
  getSolutionData() {
    this.commonService
      .callApi(this.callAPIConstants.GetSolutionData, '', 'get', false, false)
      .then((success) => {
        if (success.status === 1) {
          this.tagListArray = success.data.tagList;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  get f() {
    return this.myForm.controls;
  }
  myForm: FormGroup;
  ngOnInit() {
    this.getSolutionData();
    setTimeout(() => {
      this.postId = this.bsModalRef?.content?.modalData.postId;
    });
  }

  /****************************************************************************
     @Purpose     : OnFileUpload
     @Parameter   : Event,typeFile
     @Return      : Na
  /****************************************************************************/
  public userIncidentFileData: any = {};
  public filesize: any;
  public uploadedImage: any = 'assets/images/image.png';
  public uploadedVideoImage: any = 'assets/images/multimedia.png';
  public userIncidentFilePath: Array<any> = [];
  public userPhotoAndVideoData: Array<any> = [];
  public userSolutionFilePath: Array<any> = [];
  public solutionImageAndVideos: Array<any> = [];
  myFiles: string[] = [];
  onFileSelected(event, typeFile?) {
    if (event.target.files.length) {
      var input = event.target;
      var allowedExtensions =
        /(\.jpg|\.jpeg|\.png)|\.mp4|\.avi|.mov|.mpg|.3gp|.asf$/i;
      this.filesize = event.target.files[0].size / 1024 / 1024 + 'MB';
      if (allowedExtensions.exec(event.target.value)) {
        var input = event.target;
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.userIncidentFileData.uploadedPhoto = input.files[0];
          var fd = new FormData();
          if (input.files[0].type.includes('video')) {
            this.userIncidentFileData.Type = 'video';
            event.srcElement.value = '';
          } else {
            this.userIncidentFileData.Type = 'image';
            event.srcElement.value = '';
          }
          fd.append('moduleName', typeFile);
          fd.append('file', this.userIncidentFileData.uploadedPhoto);
          this.commonService
            .callApi(
              this.callAPIConstants.IncidentFileUpload,
              fd,
              'post',
              false,
              true
            )
            .then((success) => {
              if (success.status === 1) {
                if (success.data.filePath.includes('images/')) {
                  var splitFilePath =
                    _.toString(_.split(success.data.filePath, 'images/')[1]) ||
                    'images/';
                } else {
                  var splitFilePath =
                    _.toString(_.split(success.data.filePath, 'videos/')[1]) ||
                    'videos/';
                }

                var filePath = {
                  filePath: splitFilePath,
                  fileType: success.data.fileType,
                };
                let data = {
                  type: success.data.fileType,
                  path: success.data.filePath,
                };

                if (typeFile === 'incident') {
                  this.userPhotoAndVideoData.push(data);
                  this.userIncidentFilePath.push(filePath);
                } else {
                  this.solutionImageAndVideos.push(data);
                  this.userSolutionFilePath.push(filePath);
                }
              } else {
                this.showErrorService.popToast('error', success.message);
              }
            });
        };
        reader.readAsDataURL(input.files[0]);
        return true;
      } else if (!allowedExtensions.exec(event.target.value)) {
        this.showErrorService.popToast(
          'error',
          'Please select jpeg, jpg or png file.'
        );
        return false;
      }

      return true;
    }
  }

  /****************************************************************************
     @Purpose     : RemoveFile
     @Parameter   : data,index
     @Return      : Na
  /****************************************************************************/
  removeData(data, index, type) {
    if (type === 'incident') {
      this.userPhotoAndVideoData.splice(index, 1);
      this.userIncidentFilePath.splice(index, 1);
    } else {
      this.solutionImageAndVideos.splice(index, 1);
      this.userSolutionFilePath.splice(index, 1);
    }
    let FileData = {
      filePath: data.path,
    };
    this.commonService
      .callApi(
        this.callAPIConstants.DeleteFileUpload,
        FileData,
        'post',
        false,
        false
      )
      .then((success) => {});
  }
  updateObj(preObj, latestObj) {
    var latest = { ...preObj, ...latestObj };
    return latest;
  }

  /****************************************************************************
   @Purpose     : save FormControl
   @Parameter   : event,id,control,arr,newObj
   @Return      : NA
   /****************************************************************************/
  public showErr = false;

  saveProduct() {
    let method;

    let dataObj = {
      title: this.myForm.value.title,
      parentIncident: this.postId,
      description: this.myForm.value.description,
      datePosted: '2021-07-27T07:15:39.619Z',
      tags: this.myForm.value.tags ? this.myForm.value.tags : [],
      media: this.userSolutionFilePath ? this.userSolutionFilePath : [],
    };

    this.commonService
      .callApi(
        this.callAPIConstants.AddAdminSolution,
        dataObj,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.broadCasterService.broadcast('listingAddUpdate', true);
          this.showErrorService.popToast('success', success.message);
        } else {
          this.showErrorService.popToast('error', success.message);
          this.router.navigate([
            this.URLConstants.VIEWPOSTSOLUTIONS + this.postId,
          ]);
        }
        this.bsModalRef.hide();
      });
  }
}
