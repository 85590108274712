import { Component, OnInit } from '@angular/core';

// interface

// constants

// services
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { CommonService } from 'src/app/common/service/common.service';
import * as countries from 'src/app/common/constants/json/countries.json';
import * as timezones from 'src/app/common/constants/json/timezone.json';
import * as currencies from 'src/app/common/constants/json/currency.json';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styles: [],
})
export class CommonModalComponent implements OnInit {
  public commonObj: any = {};
  public submitted: any;
  public type;
  public id;
  public apiData: any = {};
  public countryArr: Array<any> = [];
  public countries: any = countries.default;
  public timezones: any = timezones.default;
  public currencies: any = currencies.default;
  public countryCode: any;
  public phoneCode: any;
  public currency: any;
  public filteredTimezones: any;
  public filteredCurrencies: any;
  public filterCur: any;

  callAPIConstants = callAPIConstants;
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService
  ) {}

  ngOnInit(): void {
    if (this.type == 'currencyListing' || this.type == 'timezoneListing') {
      this.commonService
        .callApi(
          this.callAPIConstants.getCountriesList,
          '',
          'post',
          false,
          false
        )
        .then((res) => {
          if (res.status === 1) {
            this.countryArr = res.data;
          }
        });
    }
    if (this.id) {
      let apiName: any = {};
      if (this.type == 'countriesListing') {
        apiName = this.callAPIConstants.getCountryDetails;
        this.apiData.countryId = this.id;
      } else if (this.type == 'currencyListing') {
        apiName = this.callAPIConstants.getCurrencyDetails;
        this.apiData.currencyId = this.id;
      } else if (this.type == 'timezoneListing') {
        apiName = this.callAPIConstants.getTimezoneDetails;
        this.apiData.timezoneId = this.id;
      }
      this.commonService
        .callApi(`${apiName}/${this.id}`, '', 'get')
        .then((res) => {
          if (res.status === 1) {
            this.commonObj = res.data;
            this.commonObj.countryId = res.data.countryId._id;
          }
        });
    }
    this.commonObj.status = true;
  }
  createUpdate(form, commonObj) {
    this.submitted = true;
    let apiName: any = {};
    if (this.type === 'countriesListing') {
      apiName = this.callAPIConstants.addUpdateCountry;
      this.apiData.countryName = this.commonObj.countryName;
      this.apiData.countryCode = this.commonObj.countryCode;
      this.apiData.phoneCode = this.commonObj.phoneCode;
      this.apiData.currency = this.commonObj.currency;
    } else if (this.type == 'currencyListing') {
      apiName = this.callAPIConstants.addUpdateCurrency;
      this.apiData.countryId = this.commonObj.countryId;
      this.apiData.currency = this.commonObj.currency;
      this.apiData.currency = 'INR';
    } else if (this.type == 'timezoneListing') {
      apiName = this.callAPIConstants.addUpdateTimezone;
      this.apiData.countryName = this.commonObj.countryName;
      this.apiData.countryId = this.commonObj.countryId;
      this.apiData.timezone = this.commonObj.timezone;
    }
    if (form.valid) {
      this.commonService
        .callApi(apiName, this.apiData, 'post')
        .then((response) => {
          this.bsModalRef.hide();
          if (response.status === 1) {
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', response.message);
          } else {
            this.showErrorService.popToast('error', response.message);
          }
        });
    }
  }

  /*************************************************************
  @Purpose     : Get List on Dropdown
  @Parameter   : NA
  @Return      : currencyList/timezoneList
  /*************************************************************/

  onOptionsSelected(event) {
    if (this.type == 'timezoneListing') {
      this.filteredTimezones = this.timezones.filter(
        (a) => a.country_code === event.countryCode
      );
      this.commonObj.filteredTimezones = this.filteredTimezones[0].timezones;
    } else {
      this.filteredCurrencies = this.currencies.countries.country;
      this.filterCur = this.filteredCurrencies.filter(
        (a) => a.countryCode === event.countryCode
      );
      this.commonObj.filterCurrencies = this.filterCur;
    }
  }

  onOptionsSelectedForCountries(event) {
    this.commonObj.countryCode = event.code;
    this.commonObj.phoneCode = event.phoneCode;
    this.commonObj.currency = event.currency;
  }
}
