import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowErrorService } from '../../common/service/show-error.service';

import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ThemeService } from 'src/app/common/service/theme/theme.service';

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: [],
})
export class EnterOtpComponent implements OnInit {
  public isSettingOpen: boolean = false;
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  phoneNumber: string = '';
  otpNumber: string = '';
  errorMsg: string = '';
  otpNum: string;
  showOtpComponent = true;
  public verifyOTPObj: any = {};
  public sendOTPObj: any = {};
  timeLeft: number = 300;
  timeLeftInMinutes: string = '';
  interval;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '40px',
      height: '40px',
    },
  };

  constructor(
    private themeService: ThemeService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private showErrorService: ShowErrorService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.phoneNumber = params.phNum;
      this.otpNumber = params.otp;
    });
  }

  ngOnInit(): void {
    this.startTimer();
    var isDark =
      this.localStorageService.getToken('theme') == 'Dark' ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById('otpSwitchTheme').setAttribute('checked', '');
    this.showErrorService.verifyOtpToast(this.otpNumber);
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        var minutes = Math.floor(this.timeLeft / 60);
        this.timeLeftInMinutes =
          '0' + minutes + ':' + (this.timeLeft - minutes * 60);
        this.timeLeft--;
      }
    }, 1000);
  }
  onVerify() {
    if (this.otpNum != undefined && this.otpNum.length == 6) {
      this.verifyOTPObj = {
        mobile: '+91-' + this.phoneNumber,
        otpType: 'NewAdmin',
        otp: this.otpNum,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.VerifyOTP,
          this.verifyOTPObj,
          'post',
          true,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message);
            this.router.navigate([this.URLConstants.LOGIN]);
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
    } else {
      this.errorMsg = 'Please enter 6-digit OTP';
    }
  }

  onOtpChange(otpNum) {
    if (otpNum.length == '6') {
      this.errorMsg = '';
    }
    this.otpNum = otpNum;
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.otpNum = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  resendOTP() {
    this.sendOTPObj = {
      mobile: '+91-' + this.phoneNumber,
      otpType: 'NewAdmin',
    };
    this.commonService
      .callApi(
        this.callAPIConstants.SendOTP,
        this.sendOTPObj,
        'post',
        true,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.showErrorService.verifyOtpToast(success.otp);

          this.timeLeft = 300;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }
}
