<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{ bsModalRef?.content?.modalData?.name ? 'Edit Role' : 'Add Role' }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, HealthcareRoleData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label>Role name<sup class="bx bxs-star danger"></sup></label>

            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Enter Role "
              name="name"
              id="_id"
              #name="ngModel"
              [(ngModel)]="HealthcareRoleData.name"
              required
            />
            <div
              class="error-block text-left"
              *ngIf="(submitted || name.touched) && name.invalid"
            >
              Enter Role name
            </div>
          </div>
          <div class="form-group">
            <label>Country<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="country"
              [items]="countries"
              [clearable]="true"
              bindValue="name"
              bindLabel="name"
              #country="ngModel"
              [(ngModel)]="HealthcareRoleData.country"
              [searchable]="true"
              autocomplete="country"
              placeholder="Enter country "
              (change)="setValueForSelect('selectCountry', $event)"
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || country.touched) && country.invalid"
            >
              Select country
            </div>
          </div>

          <div class="form-group">
            <label>Designation<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="designation"
              [items]="filteredDesignationList"
              [clearable]="true"
              bindLabel="name"
              #designation="ngModel"
              [(ngModel)]="HealthcareRoleData.designation"
              bindvalue="_id"
              [searchable]="true"
              autocomplete="designation"
              placeholder="Enter Designation "
              (change)="setValueForSelect('selectCountry', $event)"
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || designation.touched) && designation.invalid"
            >
              Select Designation
            </div>
          </div>

          <div class="form-group">
            <label>Departments<sup class="bx bxs-star danger"></sup></label>
            <label class="sr-only" for="tagfor">Departments</label>
            <ng-select
              name="departments"
              class="custom-slectmenu with-tag"
              [items]="departmentsList"
              bindLabel="name"
              bindValue="_id"
              placeholder="Select Departments"
              [multiple]="true"
              #departments="ngModel"
              [(ngModel)]="HealthcareRoleData.departments"
            >
              <ng-template ng-option-tmp let-item="item">
                <div
                  container="body"
                  class="text-left"
                  title="{{ item.name }}"
                  placement="right"
                >
                  {{ item.name }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="HealthcareRoleData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
