import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ThemeService } from 'src/app/common/service/theme/theme.service';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [],
})
export class ResetPasswordComponent implements OnInit {
  // Variable declarations
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen: boolean = false;
  fieldTextType: boolean;
  fieldTextType1: boolean;
  public resetPassword: any = {};
  public showErr = false;
  public resetPasswordToken: any;

  constructor(
    private themeService: ThemeService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private showErrorService: ShowErrorService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      let industry = params['industry'] ? params['industry'] : '';
      this.resetPasswordToken = params['token'] ? params['token'] : '';
      if (industry !== '') {
        this.localStorageService.setToken('industry', industry);
      }
    });
    var isDark =
      this.localStorageService.getToken('theme') == 'Dark' ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document
        .getElementById('resetPassSwitchTheme')
        .setAttribute('checked', '');
  }

  onResetPassword(form) {
    this.resetPassword = {
      password: form.value.password,
      token: this.resetPasswordToken,
    };
    if (form.value.password !== form.value.confirmpassword) {
      this.showErr = true;
    } else {
      this.showErr = false;
      this.commonService
        .callApi(
          this.callAPIConstants.ResetInstitutionPassword,
          this.resetPassword,
          'post',
          true,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message);
            this.router.navigate([this.URLConstants.LOGIN]);
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
    }
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
}
