<router-outlet> </router-outlet>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  color="#dc3163"
  size="medium"
  type="ball-spin"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
