<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{
        bsModalRef?.content?.modalData?.name
          ? 'Edit Email Tag'
          : 'Add Email Tag'
      }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, emailTagData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label>Email Tag Name<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || name.touched) && name.invalid ? 'has-danger' : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter Domain "
                name="name"
                id="name"
                #name="ngModel"
                [(ngModel)]="emailTagData.name"
                required
              />
              <div
                class="error-block text-left"
                *ngIf="(submitted || name.touched) && name.invalid"
              >
                Enter email tag name
              </div>
            </div>
          </div>

          <div class="form-details">
            <div *ngIf="flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="ParentRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.emailTagData.region"
                name="region"
                #region="ngModel"
                required
                (change)="onchange($event)"
                [searchable]="true"
                placeholder="Select Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || region.touched) && region.invalid"
              >
                Select region
              </div>
            </div>
            <div *ngIf="!flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="ParentRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="this.emailTagData.region"
                bindValue="_id"
                name="region"
                #region="ngModel"
                required
                (change)="onchange($event)"
                [searchable]="true"
                placeholder="Select Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || region.touched) && region.invalid"
              >
                Select region
              </div>
            </div>
          </div>
          <!-- <div class="form-details">
            <div *ngIf="this.flag" class="form-group">
              <label>Sub-Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="subRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="emailTagData.subRegion"
                name="subRegion"
                #subRegion="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select Sub Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || subRegion.touched) && subRegion.invalid"
              >
                Select subregion Head
              </div>
            </div>
            <div *ngIf="!this.flag" class="form-group">
              <label>Sub-Region<sup class="bx bxs-star danger"></sup></label>

              <ng-select
                class="text-input"
                [items]="subRegionList"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="emailTagData.subRegion"
                bindValue="_id"
                name="subRegion"
                #subRegion="ngModel"
                required
                (change)="onSubChange($event)"
                [searchable]="true"
                placeholder="Select sub Region "
                autocomplete="region"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="(submitted || subRegion.touched) && subRegion.invalid"
              >
                Select subregion Head
              </div>
            </div>
          </div> -->
          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>

            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="emailTagData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
