import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';

// constants
import { URLConstants } from '../constants/routerLink-constants';

@Injectable({
  providedIn: 'root',
})
export class GetPermissionsService {
  URLConstants = URLConstants;
  public defaultPermissionConstants = {
    create: true,
    delete: true,
    edit: true,
    read: true,
  };

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private router: Router
  ) {}

  /****************************************************************************
  @PURPOSE      : Get Pemission according to Role
  @PARAMETERS   : key
  @RETURN       : roleKey
  /****************************************************************************/
  getRolePermissions(key) {
    let role;
    if (this.localStorageService.getToken('role_permission')) {
      role = this.commonService.decrypt(
        this.localStorageService.getToken('role_permission')
      );
      let roleKey: any;
      switch (key) {
        case 'userAccess':
          {
            roleKey = role.userAccess;
          }
          break;
        case 'incidentManagementAccess':
          {
            roleKey = role.incidentManagementAccess;
          }
          break;
        case 'regionManagementAccess':
          {
            roleKey = role.regionManagementAccess;
          }
          break;
        case 'domainManagementAccess':
          {
            roleKey = role.domainManagementAccess;
          }
          break;
        case 'solutionManagementAccess':
          {
            roleKey = role.solutionManagementAccess;
          }
          break;
        case 'departmentManagementAccess':
          {
            roleKey = role.departmentManagementAccess;
          }
          break;
        case 'designationManagementAccess':
          {
            roleKey = role.designationManagementAccess;
          }
          break;
        case 'newsManagementAccess':
          {
            roleKey = role.newsManagementAccess;
          }
          break;
        case 'healthcareRoleManagementAccess':
          {
            roleKey = role.healthcareRoleManagementAccess;
          }
          break;
        case 'positionManagementAccess':
          {
            roleKey = role.positionManagementAccess;
          }
          break;
        case 'expertiseManagementAccess':
          {
            roleKey = role.expertiseManagementAccess;
          }
          break;
        case 'trainingManagementAccess':
          {
            roleKey = role.trainingManagementAccess;
          }
          break;
        case 'mechanicalFailureManagementAccess':
          {
            roleKey = role.mechanicalFailureManagementAccess;
          }
          break;
        case 'airlineManagementAccess':
          {
            roleKey = role.airlineManagementAccess;
          }
          break;
        case 'placeOfServiceManagementAccess':
          {
            roleKey = role.placeOfServiceManagementAccess;
          }
          break;
        case 'rolesAccess':
          {
            roleKey = role.rolesAccess;
          }
          break;
        case 'subRegionManagementAccess':
          {
            roleKey = role.subRegionManagementAccess;
          }
          break;
        case 'tagManagementAccess':
          {
            roleKey = role.tagManagementAccess;
          }
          break;
        case 'adminUserAccess':
          {
            roleKey = role.adminUserAccess;
          }
          break;
        case 'cmsPagesAccess':
          {
            roleKey = role.cmsPagesAccess;
          }
          break;
        case 'reachUsManagementAccess':
          {
            roleKey = role.reachUsManagementAccess;
          }
          break;
        case 'requestedDeleteIncidentAccess':
          {
            roleKey = role.requestedDeleteIncidentAccess;
          }
          break;
        case 'requestedDeleteSolutionAccess':
          {
            roleKey = role.requestedDeleteSolutionAccess;
          }
          break;
        case 'emailTemplate':
          {
            roleKey = role.emailTemplateAccess;
          }
          break;
        case 'reportedContentAccess':
          {
            roleKey = role.reportedContentAccess;
          }
          break;
        case 'postManagementAccess':
          {
            roleKey = role.postManagementAccess;
          }
          break;
        case 'preApprovedEmailTagManagementAccess':
          {
            roleKey = role.preApprovedEmailTagManagementAccess;
          }
          break;
        case 'preApprovedEmailsManagementAccess':
          {
            roleKey = role.preApprovedEmailsManagementAccess;
          }
          break;
        case 'commentManagementAccess':
          {
            roleKey = role.commentManagementAccess;
          }
          break;
        case 'qrCampaignManagementAccess':
          {
            roleKey = role.qrCampaignManagementAccess;
          }
          break;
        case 'replyManagementAccess':
          {
            roleKey = role.replyManagementAccess;
          }
          break;
        default:
          {
            roleKey = this.defaultPermissionConstants;
          }
          break;
      }
      return roleKey;
    }
  }
  /****************************************************************************/
  //requestedDeleteIncidentAccess
  /****************************************************************************
  @PURPOSE      : Hide Action In Responsive Data Table
  @PARAMETERS   : tempData
  @RETURN       : NA
  /****************************************************************************/
  hideActionInTable(tempData) {
    const index = tempData.findIndex((o) => o.type === 'action');
    return (tempData[index].isVisible = false);
  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Common function for AUTH GUARD
                  (canActivate gives value False and Routes to Dashboard)
  @PARAMETERS   : condition
  @RETURN       : Boolean
  /****************************************************************************/
  CanActivateFalseRouteDashboard(condition) {
    if (condition) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }
  /****************************************************************************/
}
