import { Component, Input, OnInit } from '@angular/core';
// plugin
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  @Input() action;
  @Input() shipmentId;
  @Input() modalRef;
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  Data: any;
  buttonName: any = 'Add';
  devChecklistId: any;
  modalData: any;
  notFoundText1: any;
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    private modalService: BsModalService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.Data = this.bsModalRef?.content?.modalData.data;
    });
  }
}
