import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { messages } from '../constants/errorMessagesData';

@Injectable()
export class ErrorMessages {
  public MSG = messages as any;

  constructor() {}
  /*****************************************************************************************
  @PURPOSE      : To Show Error on Form validations
  @PARAMETERS   : field, error
  @RETURN       : message
  /*****************************************************************************************/
  getError(field, error) {
    let message = '';
    if (error) {
      if (error.required) {
        const required = this.MSG.ERROR.REQUIRED;
        switch (field) {
          case 'infographicChartType':
            {
              message = required.infographicMethod;
            }
            break;
          case 'infographicMethod':
            {
              message = required.infographicMethod;
            }
            break;
          case 'infographicTitle':
            {
              message = required.infographicTitle;
            }
            break;
          case 'username':
            {
              message = required.Username;
            }
            break;
          case 'firstName':
            {
              message = required.firstName;
            }
            break;
          case 'lastName':
            {
              message = required.lastName;
            }
            break;
          case 'userName':
            {
              message = required.userName;
            }
            break;
          case 'mobile':
            {
              message = required.mobile;
            }
            break;
          case 'password':
            {
              message = required.Password;
            }
            break;
          case 'oldPassword':
            {
              message = required.currentPassword;
            }
            break;
          case 'amount':
            {
              message = required.Amount;
            }
            break;
          case 'newPassword':
            {
              message = required.newPassword;
            }
            break;
          case 'confirmPassword':
            {
              message = required.confirmPassword;
            }
            break;
          case 'firstname':
            {
              message = required.firstname;
            }
            break;
          case 'lastname':
            {
              message = required.lastname;
            }
            break;
          case 'phoneNo':
            {
              message = required.PhoneNo;
            }
            break;
          case 'emailId':
            {
              message = required.emailId;
            }
            break;
          case 'roleName':
            {
              message = required.roleName;
            }
            break;
          case 'role':
            {
              message = required.role;
            }
            break;
          case 'filterName':
            {
              message = required.filterName;
            }
            break;
          case 'emailKey':
            {
              message = required.emailKey;
            }
            break;
          case 'subject':
            {
              message = required.subject;
            }
            break;
          case 'fromEmailId':
            {
              message = required.fromEmailId;
            }
            break;
          case 'tag':
            {
              message = required.tag;
            }
            break;
          case 'category':
            {
              message = required.category;
            }
            break;
          case 'description':
            {
              message = required.description;
            }
            break;
          case 'categoryName':
            {
              message = required.categoryName;
            }
            break;
          case 'faqsCategory':
            {
              message = required.faqsCategory;
            }
            break;
          case 'answer':
            {
              message = required.answer;
            }
            break;
          case 'question':
            {
              message = required.question;
            }
            break;
          case 'blogTitle':
            {
              message = required.blogTitle;
            }
            break;
          case 'slug':
            {
              message = required.slug;
            }
            break;

          case 'emailTemplateId':
            {
              message = required.emailTemplateId;
            }
            break;
          case 'host':
            {
              message = required.host;
            }
            break;
          case 'port':
            {
              message = required.port;
            }
            break;
          case 'defaultFromEmail':
            {
              message = required.defaultFromEmail;
            }
            break;
          case 'metaTitle':
            {
              message = required.metaTitle;
            }
            break;
          case 'metaDescription':
            {
              message = required.metaDescription;
            }
            break;
          case 'metaKeyword':
            {
              message = required.metaKeyword;
            }
            break;
          case 'pageTitle':
            {
              message = required.pageTitle;
            }
            break;
          case 'currency':
            {
              message = required.currency;
            }
            break;
          case 'dateFormat':
            {
              message = required.dateFormat;
            }
            break;
          case 'defaultAdminEmail':
            {
              message = required.defaultAdminEmail;
            }
            break;
          case 'templateName':
            {
              message = required.templateName;
            }
            break;
          case 'countryName':
            {
              message = required.countryName;
            }
            break;
          case 'countryId':
            {
              message = required.countryId;
            }
            break;
          case 'countryCode':
            {
              message = required.countryCode;
            }
            break;
          case 'companyName':
            {
              message = required.companyName;
            }
            break;
          case 'googleToken':
            {
              message = required.googleToken;
            }
            break;
          case 'facebookAppId':
            {
              message = required.facebookAppId;
            }
            break;
          case 'pKey':
            {
              message = required.pKey;
            }
            break;
          case 'sKey':
            {
              message = required.sKey;
            }
            break;
          case 'mode':
            {
              message = required.mode;
            }
            break;
          case 'clientId':
            {
              message = required.clientId;
            }
            break;
          case 'clientSecret':
            {
              message = required.clientSecret;
            }
            break;
          case 'authToken':
            {
              message = required.authToken;
            }
            break;
          case 'accountSid':
            {
              message = required.accountSid;
            }
            break;
          case 'phoneCode':
            {
              message = required.phoneCode;
            }
            break;
          case 'mainCurrency':
            {
              message = required.mainCurrency;
            }
            break;
          case 'timezone':
            {
              message = required.timezone;
            }
            break;
          case 'twitterLink':
            {
              message = required.twitterLink;
            }
            break;
          case 'facebookLink':
            {
              message = required.facebookLink;
            }
            break;
          case 'googleLink':
            {
              message = required.googleLink;
            }
            break;
          case 'linkedinLink':
            {
              message = required.linkedinLink;
            }
            break;
          case 'instagramLink':
            {
              message = required.instagramLink;
            }
            break;
          case 'websiteLink':
            {
              message = required.websiteLink;
            }
            break;
          case 'dob':
            {
              message = required.dob;
            }
            break;
          case 'country':
            {
              message = required.country;
            }
            break;
          case 'languages':
            {
              message = required.languages;
            }
            break;
          case 'address':
            {
              message = required.address;
            }
            break;
          case 'headerSnippet':
            {
              message = required.headerSnippet;
            }
            break;
          case 'footerSnippet':
            {
              message = required.footerSnippet;
            }
            break;
          case 'analyticsSnippet':
            {
              message = required.analyticsSnippet;
            }
            break;
          case 'authToken':
            {
              message = required.authToken;
            }
            break;
          case 'accountSid':
            {
              message = required.accountSid;
            }
            break;
          case 'image':
            {
              message = required.image;
            }
            break;
          case 'caption':
            {
              message = required.caption;
            }
            break;
          case 'description':
            {
              message = required.description;
            }
            break;
          case 'mobileNo':
            {
              message = required.mobileNo;
            }
            break;
        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'emailId':
            {
              message = pattern.Email;
            }
            break;
          case 'newPassword':
            {
              message = pattern.newPassword;
            }
            break;
          case 'confirmPassword':
            {
              message = pattern.confirmPassword;
            }
            break;
          case 'adminEmail':
            {
              message = pattern.adminEmail;
            }
            break;
          case 'fromEmailId':
            {
              message = pattern.fromEmailId;
            }
            break;
          case 'defaultFromEmail':
            {
              message = pattern.Email;
            }
            break;
          case 'defaultAdminEmail':
            {
              message = pattern.Email;
            }
            break;
          case 'link':
            {
              message = pattern.link;
            }
            break;
        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'answer':
            {
              message = minlength.answer;
            }
            break;
          case 'question':
            {
              message = minlength.question;
            }
            break;
          case 'description':
            {
              message = minlength.description;
            }
            break;
          case 'mobile':
            {
              message = minlength.PhoneNo;
            }
            break;
          case 'confirmPassword':
            {
              message = minlength.confirmPassword;
            }
            break;
          case 'newPassword':
            {
              message = minlength.newPassword;
            }
            break;
          case 'port':
            {
              message = minlength.port;
            }
            break;
        }
      }
      return message;
    }
  }
  /*****************************************************************************************/
}
