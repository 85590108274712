import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { ErrorMessages } from './errorMessages';

@Injectable({
  providedIn: 'root',
})
export class ShowErrorService {
  constructor(private errorMessage: ErrorMessages) {}

  /***************************************************************************
	@PURPOSE      : To show validation message
	@PARAMETERS   : field_name, errorObj?
	@RETURN       : error message
	****************************************************************************/
  showError(field, errorObj?) {
    return this.errorMessage.getError(field, errorObj);
  }
  /****************************************************************************/

  /**************************************************************************
	@PURPOSE      : We can use following function to use Toaster Service.
	@PARAMETERS   : field_name, errorObj?
	@RETURN       : error message
	/**************************************************************************/
  popToast(type, title, timer = 2000) {
    swal({
      position: 'center',
      type,
      text: title,
      showConfirmButton: false,
      timer: timer,
      customClass: 'custom-toaster',
    });
  }

  verifyOtpToast(title) {
    swal({
      position: 'top',
      text: title,
      showConfirmButton: false,
      //showProgressSteps: '',

      timer: 100000,
      // didOpen: (toast) => {
      // 	toast.addEventListener('mouseenter', swal.stopTimer)
      // 	toast.addEventListener('mouseleave', swal.resumeTimer)
      //   }
    });
  }

  // const Toast = swal.mixin({
  //   toast: true,
  //   position: 'top-end',
  //   showConfirmButton: false,
  //   timer: 3000,
  //   timerProgressBar: true,
  //   didOpen: (toast) => {
  //     toast.addEventListener('mouseenter', swal.stopTimer)
  //     toast.addEventListener('mouseleave', swal.resumeTimer)
  //   }
  // })

  // Toast.fire({
  //   icon: 'success',
  //   title: 'Signed in successfully'
  // })

  confirmpopToast(type, title) {
    return swal({
      // title: 'Are you sure?',
      text: title,
      type,
      showCancelButton: true,
      confirmButtonText: 'YES',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn btn--outline-primary',
    });
  }

  showMessageToast(title, message) {
    return swal({
      title: title,
      text: message,
    });
  }

  showMediaModal(url) {
    return swal({
      title: '',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      text: '',
      imageUrl: url,
    });
  }

  showVideoModal(url) {
    return swal({
      title: '',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      html: `<video class="mt-5 " controls><source src=${url} type="video/mp4"></video>`,
    });
  }
  /*************************************************************************/
}
