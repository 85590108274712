<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <div class="icon mr-2"><span class="bx bxs-plus-circle"></span></div>
    <h5 class="mb-0">{{ testbsModalRef?.content?.modalData?.title }}</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="testbsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm">
      <div class="card-body">
        <div class="container">
          <!-- start FAQs Listing -->

          <div class="form-group">
            <label>CATEGORY<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || categoryName.touched) && categoryName.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <ng-select
                [items]="category"
                [clearable]="false"
                type="text"
                name="faqCategoryName"
                bindLabel="faqCategoryName"
                bindValue="faqCategoryName"
                placeholder="Select Category"
                #categoryName="ngModel"
                [(ngModel)]="faqsList.faqCategoryName"
                required
              >
              </ng-select>

              <div
                class="error-block text-left"
                *ngIf="
                  (submitted || categoryName.touched) && categoryName.invalid
                "
              >
                {{
                  showErrorService.showError(
                    'categoryName',
                    categoryName.errors
                  )
                }}
              </div>
            </div>
          </div>

          <div class="modalTitle mb-2">
            <span class="bx bxs-plus-circle"> </span
            ><label class="new-faq-cat" (click)="categoryOpen()"
              >Add New Category</label
            >
          </div>

          <div class="form-group">
            <label>QUESTION<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || question.touched) && question.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Add your question"
                name="question"
                minlength="10"
                #question="ngModel"
                [(ngModel)]="faqsList.faqQuestion"
                required
              />

              <div
                class="error-block text-left"
                *ngIf="(submitted || question.touched) && question.invalid"
              >
                {{ showErrorService.showError('question', question.errors) }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>ANSWER<sup class="bx bxs-star danger"></sup></label>
            <div
              class=""
              [ngClass]="
                (submitted || answer.touched) && answer.invalid
                  ? 'has-danger'
                  : ''
              "
            >
              <textarea
                autocomplete="off"
                class="form-control"
                rows="4"
                type="text"
                minlength="20"
                placeholder="Add your answer"
                name="answer"
                #answer="ngModel"
                minlength="20"
                [(ngModel)]="faqsList.faqAnswer"
                required
              ></textarea>

              <div
                class="error-block text-left"
                *ngIf="(submitted || answer.touched) && answer.invalid"
              >
                {{ showErrorService.showError('answer', answer.errors) }}
              </div>
            </div>
          </div>
          <!-- end FAQs Listing -->

          <!-- start FAQs category -->
          <!-- <div class="form-group" *ngIf="this.bsModalRef?.content?.modalData?.type1 !== 'faqsListing' || categoryOpenModel">
                        <label>ADD CATEGORY<sup class="bx bxs-star danger"></sup></label>
                        <div class=""
                            [ngClass]="((submitted || faqsCategory.touched) && faqsCategory.invalid)?'has-danger':''">
                            <input autocomplete="off" class="form-control" type="text" placeholder="Add category"
                                name="faqsCategory" #faqsCategory="ngModel" [(ngModel)]="faqsList.faqsCategory"
                                required>

                            <div class="error-block text-left"
                                *ngIf="((submitted || faqsCategory.touched) && faqsCategory.invalid)">
                                {{showErrorService.showError('faqsCategory', faqsCategory.errors)}}
                            </div>
                        </div>
                    </div> -->
          <!-- end FAQs category -->

          <div class="d-flex align-items-center justify-content-end">
            <button
              type="submit"
              class="btn btn-primary mr-2"
              (click)="createUpdate(form, faqsList)"
            >
              {{
                testbsModalRef?.content?.modalData?.title ===
                'Update FAQs
                            '
                  ? 'Update'
                  : 'Add'
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
