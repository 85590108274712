<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">Delete Reason</h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form [formGroup]="Form" (ngSubmit)="saveComment()" novalidate>
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label for="Reason"
              >Delete reason<em class="bx bxs-pencil ml-2"></em
            ></label>
            <textarea
              autosize
              autocomplete="off"
              formControlName="adminComment"
              appTextareAutoresize
              class="txta form-control"
              type="text"
              id="Reason"
              rows="4"
              placeholder="Enter Reason"
            ></textarea>
          </div>
        </div>
        <button type="submit" class="btn btn-info mx-3 px-3">
          Submit <em class="bx bxs-message-square-edit px-3"></em>
        </button>
      </div>
    </form>
  </div>
</div>
