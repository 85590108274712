<div class="d-flex align-items-center justify-content-between card-header">
  <div class="text-left d-flex align-items-center">
    <h5 class="mb-0">
      {{
        bsModalRef?.content?.modalData?.name
          ? 'Edit SubRegion'
          : 'Add SubRegion'
      }}
    </h5>
  </div>
  <div class="text-right">
    <div class="close">
      <em
        style="font-size: 25px"
        class="bx bx-x"
        (click)="bsModalRef.hide()"
      ></em>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div class="animated fadeIn">
    <form #form="ngForm" (ngSubmit)="createUpdate(form, subRegionData)">
      <div class="card-body">
        <div class="container">
          <div class="form-group">
            <label
              >Sub-Region Title<sup class="bx bxs-star danger"></sup
            ></label>
            <div
              class=""
              [ngClass]="
                (submitted || name.touched) && name.invalid ? 'has-danger' : ''
              "
            >
              <input
                autocomplete="off"
                class="form-control"
                type="text"
                placeholder="Enter Sub Region "
                name="name"
                id="name"
                #name="ngModel"
                [(ngModel)]="subRegionData.name"
                required
              />
              <div
                class="error-block text-left"
                *ngIf="(submitted || name.touched) && name.invalid"
              >
                Enter SubRegion name
              </div>
            </div>
          </div>
          <div class="form-details">
            <div *ngIf="flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="domainList | async"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="subRegionData.parentRegion"
                name="parentRegion"
                #parentRegion="ngModel"
                required
                [value]="subRegionData.region"
                [searchable]="true"
                placeholder="Select Region "
                autocomplete="region"
                [typeaheadMinLength]="0"
                [notFoundText]="notFoundText1"
                (change)="onRegion($event)"
                [typeahead]="internalAssigneDataSource"
                (focus)="focus$.next($event.target.value)"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || parentRegion.touched) && parentRegion.invalid
                "
              >
                Select region
              </div>
            </div>
            <div *ngIf="!flag" class="form-group">
              <label>Region<sup class="bx bxs-star danger"></sup></label>
              <ng-select
                class="text-input"
                [items]="domainList | async"
                [clearable]="true"
                bindLabel="name"
                [(ngModel)]="subRegionData.parentRegion"
                bindValue="_id"
                name="parentRegion"
                #parentRegion="ngModel"
                required
                [value]="subRegionData.region"
                [searchable]="true"
                placeholder="Select Region "
                autocomplete="region"
                [typeaheadMinLength]="0"
                [notFoundText]="notFoundText1"
                [typeahead]="internalAssigneDataSource"
                (focus)="focus$.next($event.target.value)"
              >
              </ng-select>
              <div
                style="color: indianred"
                class="error-block text-left"
                *ngIf="
                  (submitted || parentRegion.touched) && parentRegion.invalid
                "
              >
                Select region
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>status<sup class="bx bxs-star danger"></sup></label>
            <ng-select
              class="text-input"
              name="isActive"
              [items]="taglist"
              [clearable]="true"
              bindValue="value"
              bindLabel="name"
              #isActive="ngModel"
              [(ngModel)]="subRegionData.isActive"
              [searchable]="true"
              autocomplete="isActive"
              placeholder="Enter status "
              required
            >
            </ng-select>
            <div
              style="color: indianred"
              class="error-block text-left"
              *ngIf="(submitted || isActive.touched) && isActive.invalid"
            >
              Select status
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary mr-2">{{ buttonName }}</button>
            <button
              class="btn btn-primary mr-2"
              (click)="bsModalRef.hide()"
              type="reset"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
