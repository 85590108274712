import { Component, OnInit } from '@angular/core';

// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import * as countries from 'src/app/common/constants/json/countries.json';

@Component({
  selector: 'app-add-healthcarerole',
  templateUrl: './add-healthcarerole.component.html',
  styleUrls: ['./add-healthcarerole.component.scss'],
})
export class AddHealthcareRoleComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  public departmentsList: Array<any> = [];
  designationsList: Array<any> = [];
  public filteredDesignationList: Array<any> = [];
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  HealthcareRoleData: any = {};
  buttonName: any = 'Add';
  public countries: any = countries.default;

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getDropDownData();
    setTimeout(() => {
      if (
        this.bsModalRef?.content?.modalData?.type === 'edit_healthcarerolelist'
      ) {
        this.buttonName = 'Update';
        this.HealthcareRoleData.healthcareRoleId =
          this.bsModalRef?.content?.modalData?.healthcareRoleId;
        this.HealthcareRoleData.name =
          this.bsModalRef?.content?.modalData?.name;
        this.HealthcareRoleData.country =
          this.bsModalRef?.content?.modalData?.country;
        let temp = this.HealthcareRoleData.country;
        this.setValueForSelect('selectCountry', temp);
        this.HealthcareRoleData.designation =
          this.bsModalRef?.content?.modalData?.designation;

        this.HealthcareRoleData.departments =
          this.bsModalRef?.content?.modalData?.departments;
        console.log(this.bsModalRef?.content?.modalData?.departments);

        this.HealthcareRoleData.isActive =
          this.bsModalRef?.content?.modalData?.isActive;
      }
    }, 200);
  }
  /****************************************************************************
  @Purpose     : Add/Update department
  @Parameter   : form, department
  @Return      : NA
  /****************************************************************************/
  setValueForSelect(control, value) {
    let tempCountry = this.HealthcareRoleData.country;
    this.filteredDesignationList = this.designationsList.filter(
      (designation) => designation.country === tempCountry
    );

    if (control === 'selectCountry') {
      this.filteredDesignationList = this.filteredDesignationList;
    }
  }

  getDropDownData() {
    this.commonService
      .callApi(
        this.callAPIConstants.HealthcareRoleGetMetaData,
        '',
        'get',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.departmentsList = success.data.departmentList;
          this.designationsList = success.data.designationList;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  createUpdate(form, Data) {
    if (this.HealthcareRoleData.healthcareRoleId) {
      let dataObj = {
        healthcareRoleId: this.HealthcareRoleData.healthcareRoleId,
        name: form.value.name,
        country: form.value.country,
        designation: form.value.designation,
        departments: form.value.departments,
        isActive: form.value.isActive,
      };

      const designationName = dataObj.designation;
      const matchedDesignation = this.designationsList.find(
        (designation) => designation.name === designationName
      );

      if (matchedDesignation) {
        dataObj.designation = matchedDesignation._id;
      } else {
        console.error('ID selected');
      }

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditHealthcareRole +
              '/' +
              dataObj.healthcareRoleId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.router.navigate([this.URLConstants.HEALTHCAREROLE]);

              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.HEALTHCAREROLE]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.AddHealthcareRole, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.HEALTHCAREROLE]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
