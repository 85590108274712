import { Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-recommanded',
  templateUrl: './add-recommanded.component.html',
  styleUrls: [],
})
export class AddRecommandedComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  Regionlist: any = {};
  taglist = [
    { name: 'Approved', value: 'Approved' },
    { name: 'Rejected', value: 'Rejected' },
    { name: 'Pending', value: 'Pending' },
  ];
  domainData: any = {};
  buttonName: any = 'Add';
  devChecklistId: any;
  public industryArr = ['aviation', 'healthcare'];
  public typeList = [
    { name: 'Military' },
    { name: 'Private' },
    { name: 'Commercial' },
    { name: 'Airline' },
  ];

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}
  public flag: boolean = false;
  ngOnInit(): void {
    this.getDomainList();
    this.domainData.industry = this.localStorageService.getToken('industry');
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData.domainId) {
        this.flag = true;
        this.buttonName = 'Update';
        this.domainData.domainId =
          this.bsModalRef?.content?.modalData?.domainId;
        this.domainData.region = this.bsModalRef?.content?.modalData?.Region;
        this.domainData.name = this.bsModalRef?.content?.modalData?.name;
        this.domainData.status = this.bsModalRef?.content?.modalData?.status;
        this.domainData.industry =
          this.bsModalRef?.content?.modalData?.industry;
        this.domainData.subRegion =
          this.bsModalRef?.content?.modalData?.subRegion;
        this.domainData.displayName =
          this.bsModalRef?.content?.modalData?.displayName;
        this.domainData.aviationType = this.bsModalRef?.content?.modalData
          ?.aviationType
          ? this.bsModalRef?.content?.modalData?.aviationType
          : null;
        this.domainData.airline = this.bsModalRef?.content?.modalData?.airline;
        this.domainData.placeOfService =
          this.bsModalRef?.content?.modalData?.placeOfService;
        this.domainData.country = this.bsModalRef?.content?.modalData?.country
          ? this.bsModalRef?.content?.modalData?.country
          : null;
      }
    });
  }
  /****************************************************************************
  @Purpose     : GetDomain detail
  @Parameter   : data
  @Return      : NA
  /****************************************************************************/
  ParentRegionList: any = [];
  subRegionList: any = [];
  aviationRegionList: any = [];
  tempAviationRegionList: any = [];
  airlineList: any = [];
  placeOfServiceList: any = [];
  countryList: any = [];
  tempAirlineList: any = [];
  tempPlaceOfServiceList: any = [];
  getDomainList() {
    let parentReg;
    let subregion;
    this.commonService
      .callApi(this.callAPIConstants.DetailDomain, false, 'get')
      .then((success) => {
        this.ParentRegionList = success.data.map((element) => {
          if (element != null) {
            return element;
          }
        });
        this.aviationRegionList = success.aviationRegionList.map((element) => {
          if (element != null) {
            return element;
          }
        });

        this.tempAviationRegionList = this.aviationRegionList;

        this.airlineList = success.airlineList.map((element) => {
          if (element != null) {
            return element;
          }
        });

        this.tempAirlineList = this.airlineList;

        this.placeOfServiceList = success.placeOfServiceList.map((element) => {
          if (element != null) {
            return element;
          }
        });

        this.tempPlaceOfServiceList = this.placeOfServiceList;

        this.countryList = success.aviationCountryList.map((element) => {
          if (element != null) {
            return element;
          }
        });

        if (!_.isEmpty(this.domainData.country)) {
          console.log(this.domainData.country);
          this.aviationRegionList = this.tempAviationRegionList;
          let aviationRegions = this.aviationRegionList.filter(
            (region) => region.country === this.domainData.country
          );
          this.aviationRegionList = aviationRegions;

          let services = this.placeOfServiceList.filter(
            (service) => service.country === this.domainData.country
          );
          this.placeOfServiceList = services;

          let airlines = this.airlineList.filter(
            (airline) => airline.country === this.domainData.country
          );
          this.airlineList = airlines;
        }
      });
  }
  /****************************************************************************
  @Purpose     : Get Event data
  @Parameter   : Event
  @Return      : NA
  /****************************************************************************/
  parentRegionData: any;
  subRegionData: any;
  onchange(event) {
    this.subRegionList = event.subRegionList;
    this.parentRegionData = event._id;
  }
  onSubChange(event) {
    this.subRegionData = event._id;
  }
  onChangeCountry(event) {
    this.aviationRegionList = this.tempAviationRegionList;
    let aviationRegions = this.aviationRegionList.filter(
      (region) => region.country === event.name
    );
    this.aviationRegionList = aviationRegions;

    this.placeOfServiceList = this.tempPlaceOfServiceList;
    let services = this.placeOfServiceList.filter(
      (service) => service.country === event.name
    );
    this.placeOfServiceList = services;

    this.airlineList = this.tempAirlineList;
    let airlines = this.airlineList.filter(
      (airline) => airline.country === event.name
    );
    this.airlineList = airlines;

    this.domainData.airline = null;
    this.domainData.region = null;
    this.domainData.placeOfService = null;
  }
  parentRegionData1: any;
  subRegionData1: any;
  /****************************************************************************
  @Purpose     : Get Domain data
  @Parameter   :  Data -id
  @Return      : NA
  /****************************************************************************/
  getDomainData(data) {
    this.commonService
      .callApi(
        this.callAPIConstants.GetDomainById + data,
        { domainId: data },
        'post'
      )
      .then((success) => {
        if (success.status == 1) {
          this.parentRegionData1 = success.data.region._id;
          this.subRegionData1 = success.data.subRegion._id;
        } else {
          this.showErrorService.popToast('error', success.message);
          this.router.navigate([this.URLConstants.SUB_REGION]);
        }
      });
  }

  industryChange() {
    this.domainData.region = null;
  }
  /****************************************************************************
  @Purpose     : Add/Update Domain
  @Parameter   : form, DomainData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.domainData.domainId) {
      let dataObj = {
        domainId: this.domainData.domainId,
        name: form.value.name ? form.value.name : '',
        displayName: form.value.displayName,
        region: this.parentRegionData
          ? this.parentRegionData
          : form.value.region._id,
        subRegion: this.subRegionData
          ? this.subRegionData
          : form.value.subRegion._id,
        industry: this.domainData.industry,
        status: form.value.status ? form.value.status : '',
      };
      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditRecommended + this.domainData.domainId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.RECOMMANDED_DOMAIN]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.AddRecommended,
            { ...form.value, industry: this.domainData.industry },
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.RECOMMANDED_DOMAIN]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }

  /****************************************************************************
  @Purpose     : Add/Update Domain aviation
  @Parameter   : form, DomainData
  @Return      : NA
  /****************************************************************************/

  createUpdateAviationDomain(form, Data) {
    if (this.domainData.domainId) {
      let dataObj = {
        domainId: this.domainData.domainId,
        name: form.value.name,
        displayName: form.value.displayName,
        aviationType: form.value.aviationType,
        region: form.value.region ? form.value.region._id : null,
        airline: form.value.airline ? form.value.airline._id : null,
        placeOfService: form.value.placeOfService
          ? form.value.placeOfService._id
          : null,
        status: form.value.status ? form.value.status : '',
        industry: this.domainData.industry,
        country: form.value.country,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditRecommendedAviation +
              this.domainData.domainId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.RECOMMANDED_DOMAIN]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        const data = {
          industry: this.domainData.industry,
          ...form.value,
        };
        console.log(data);
        this.commonService
          .callApi(this.callAPIConstants.AddRecommendedAviation, data, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.RECOMMANDED_DOMAIN]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }

  /****************************************************************************
  @Purpose     : Add/Update Domain industry neutral domain
  @Parameter   : form, DomainData
  @Return      : NA
  /****************************************************************************/

  createUpdateIndustryNeutralDomain(form, Data) {
    if (this.domainData.domainId) {
      let dataObj = {
        domainId: this.domainData.domainId,
        name: form.value.name,
        displayName: form.value.displayName,
        status: form.value.status ? form.value.status : '',
        industry: this.domainData.industry,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditRecommendedNeutral +
              this.domainData.domainId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.RECOMMANDED_DOMAIN]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        const data = {
          industry: this.domainData.industry,
          ...form.value,
        };
        console.log(data);
        this.commonService
          .callApi(this.callAPIConstants.AddRecommendedNeutral, data, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.RECOMMANDED_DOMAIN]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }

  checkIndustry(industry, notInclude) {
    if (
      _.includes(this.domainData.industry, industry) &&
      !_.includes(this.domainData.industry, notInclude)
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkBothIndustries() {
    if (
      _.includes(this.domainData.industry, 'healthcare') &&
      _.includes(this.domainData.industry, 'aviation')
    ) {
      return true;
    } else {
      return false;
    }
  }
}
