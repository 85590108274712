import { Component, OnInit } from '@angular/core';
// services
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
@Component({
  selector: 'app-addblog',
  templateUrl: './addblog.component.html',
  styleUrls: ['./addblog.component.scss'],
})
export class AddblogComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  submitted: any;
  blogCat: any = {};
  public id: any;
  blogCategory_Id: any;
  loginUserFirstName: string;
  loginUserLastName: string;
  loginUserId: string;
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {
    if (
      ((this.loginUserFirstName =
        this.localStorageService.getToken('firstname')),
      (this.loginUserLastName = this.localStorageService.getToken('lastname')))
    )
      this.loginUserId = this.localStorageService.getToken('userId');
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.bsModalRef.content.modalData.type === 'edit_blog_category') {
        this.blogCategory_Id =
          this.bsModalRef.content.modalData.blogCategory_Id;
        this.commonService
          .callApi(
            this.callAPIConstants.GetBlogCategoryDetails + this.blogCategory_Id,
            '',
            'get',
            false,
            false
          )
          .then((res) => {
            if (res.status === 1) {
              this.blogCat = res.data;
            }
          });
      }
    });
  }

  /****************************************************************************
  @Purpose     : Add/Update Blogcategory
  @Parameter   : form, blogCat
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, blogCat) {
    if (form.valid) {
      blogCat['postDate'] = new Date();
      blogCat['createdBy'] =
        this.loginUserFirstName + ' ' + this.loginUserLastName;
      blogCat['createdById'] = this.loginUserId;
      if (this.blogCategory_Id) {
        blogCat['id'] = this.blogCategory_Id;
      }
      this.commonService
        .callApi(
          this.callAPIConstants.AddUpdateBlogCategory,
          blogCat,
          'post',
          false,
          false,
          false
        )
        .then((res) => {
          this.bsModalRef.hide();
          if (res.status === 1) {
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', res.message);
          } else {
            this.showErrorService.popToast('error', res.message);
          }
        });
    } else {
      this.submitted = true;
    }
  }
  /****************************************************************************/
}
