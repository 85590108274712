import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-add-email-tag',
  templateUrl: './add-email-tag.component.html',
  styleUrls: ['./add-email-tag.component.scss'],
})
export class AddEmailTagComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  Regionlist: any = {};
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  emailTagData: any = {};
  buttonName: any = 'Add';
  devChecklistId: any;

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}
  public flag: boolean = false;
  ngOnInit(): void {
    this.getDomainList();
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData.emailTagId) {
        this.flag = true;
        this.buttonName = 'Update';
        this.emailTagData.emailTagId =
          this.bsModalRef?.content?.modalData?.emailTagId;
        this.getEmailTagData(this.emailTagData.emailTagId);
        this.emailTagData.region = this.bsModalRef?.content?.modalData?.Region;
        this.emailTagData.name = this.bsModalRef?.content?.modalData?.name;
        this.emailTagData.isActive =
          this.bsModalRef?.content?.modalData?.isActive;
        this.emailTagData.subRegion =
          this.bsModalRef?.content?.modalData?.subRegion;
      }
    });
  }
  /****************************************************************************
  @Purpose     : GetDomain detail
  @Parameter   : data
  @Return      : NA
  /****************************************************************************/
  ParentRegionList: any = [];
  subRegionList: any = [];
  getDomainList() {
    let parentReg;
    let subregion;
    this.commonService
      .callApi(this.callAPIConstants.TagDetails, false, 'get')
      .then((success) => {
        this.ParentRegionList = success.data.map((element) => {
          if (element != null) {
            return element;
          }
        });
      });
  }
  /****************************************************************************
  @Purpose     : Get Event data
  @Parameter   : Event
  @Return      : NA
  /****************************************************************************/
  parentRegionData: any;
  subRegionData: any;
  onchange(event) {
    this.subRegionList = event.subRegionList;
    this.parentRegionData = event._id;
  }
  onSubChange(event) {
    this.subRegionData = event._id;
  }
  parentRegionData1: any;
  subRegionData1: any;
  /****************************************************************************
  @Purpose     : Get email tag data
  @Parameter   :  Data -id
  @Return      : NA
  /****************************************************************************/
  getEmailTagData(data) {
    this.commonService
      .callApi(
        this.callAPIConstants.GetEmailTagById + data,
        { emailTagId: data },
        'post'
      )
      .then((success) => {
        if (success.status == 1) {
          this.parentRegionData1 = success.data.region._id;
          this.subRegionData1 = success.data.subRegion._id;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }
  /****************************************************************************
  @Purpose     : Add/Update Domain
  @Parameter   : form, emailTagData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.emailTagData.emailTagId) {
      let dataObj = {
        emailTagId: this.emailTagData.emailTagId,
        name: form.value.name,
        region: this.parentRegionData
          ? this.parentRegionData
          : this.parentRegionData1,
        // subRegion: this.subRegionData
        //   ? this.subRegionData
        //   : this.subRegionData1,
        isActive: form.value.isActive,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditEmailTagById +
              this.emailTagData.emailTagId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.EMAILTAGS]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.AddEmailTag, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.EMAILTAGS]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
