import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, of, Subject, concat } from 'rxjs';
import {
  merge,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map,
  startWith,
} from 'rxjs/operators';

// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss'],
})
export class AddTagComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  tagData: any = {};
  buttonName: any = 'Add';
  devChecklistId: any;
  modalData: any;
  notFoundText1: any;
  public industryArr = ['aviation', 'healthcare'];
  public addAndEditTagForm: FormGroup;
  shortCodeList = [
    { name: 'en', value: 'en' },
    { name: 'de', value: 'de' },
    { name: 'fr', value: 'fr' },
  ];
  otherLangSelected: boolean = false;
  public pTagList: Observable<any>;
  public internalAssigneDataSource = new Subject<any>();
  public internalAssigneLoader: boolean = false;
  public focus$ = new Subject<string>();
  ParentTagData: any;
  industry: any;
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadTag();
    this.industry = this.localStorageService.getToken('industry');
    this.addAndEditTagForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      isActive: [true, [Validators.required]],
      language: ['en', [Validators.required]],
      definition: [''],
      industry: ['', [Validators.required]],
      keywords: this.formBuilder.array([]),
      parentId: [''],
    });
    this.addAndEditTagForm.controls['industry'].setValue(this.industry);
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData?.type === 'edit_taglist') {
        this.buttonName = 'Update';
        this.tagData._id = this.bsModalRef?.content?.modalData?._id;
        this.tagData.name = this.bsModalRef?.content?.modalData?.name;
        this.tagData.isActive = this.bsModalRef?.content?.modalData?.isActive;
        this.tagData.definition =
          this.bsModalRef?.content?.modalData?.definition;
        this.tagData.industry = this.industry;
        this.addAndEditTagForm.get('name').setValue(this.tagData.name);
        this.addAndEditTagForm.get('isActive').setValue(this.tagData.isActive);
        this.addAndEditTagForm
          .get('definition')
          .setValue(this.tagData.definition);
        this.addAndEditTagForm.get('industry').setValue(this.tagData.industry);
        this.tagData.language = this.bsModalRef?.content?.modalData?.language;
        this.bsModalRef?.content?.modalData?.keywords.forEach((keyword) => {
          this.keywords().push(this.formBuilder.group(keyword));
        });
        this.addAndEditTagForm.get('language').setValue(this.tagData.language);
        if (this.tagData.language != 'en') {
          this.otherLangSelected = true;
        }
        this.tagData.parentId =
          this.bsModalRef?.content?.modalData?.parentId?.name;
        this.addAndEditTagForm.get('parentId').setValue(this.tagData.parentId);
      }
    });
  }

  keywords(): FormArray {
    return this.addAndEditTagForm.get('keywords') as FormArray;
  }

  newKeyword(): FormGroup {
    return this.formBuilder.group({
      keyword: '',
    });
  }

  addKeywords() {
    this.keywords().push(this.newKeyword());
  }

  removeKeywords(i: number) {
    this.keywords().removeAt(i);
  }

  /****************************************************************************
     @Purpose     : SetValueForNgselect
     @Parameter   : Control,value
     @Return      : Na
  /****************************************************************************/
  setValueForSelect(control, value) {
    this.addAndEditTagForm.controls[control].setValue(value.value);
  }

  setValueForLang(control, value) {
    if (value.name != 'en') {
      this.otherLangSelected = true;
      this.addAndEditTagForm.controls[control].setValue(value.value);
    } else {
      this.otherLangSelected = false;
      this.addAndEditTagForm.controls[control].setValue(value.value);
    }
  }

  onParentTag(event) {
    this.ParentTagData = event._id;
    this.addAndEditTagForm.controls['parentId'].setValue(event._id);
  }

  setValueForIndustry(control, value) {
    this.addAndEditTagForm.controls[control].setValue(value);
  }

  clusterIds: any;
  techIds: any;
  cluster_Id: any;
  technology_Id: any;

  /****************************************************************************
  @Purpose     : Add/Update Tag
  @Parameter   : form, TagData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.tagData._id) {
      form.value.industry = this.industry;
      let dataObj = {
        _id: this.tagData._id,
        name: form.value.name,
        isActive: form.value.isActive,
        language: form.value.language,
        definition: form.value.definition,
        industry: form.value.industry,
        keywords: this.addAndEditTagForm.value.keywords
          ? this.addAndEditTagForm.value.keywords
          : '',
        parentId: this.ParentTagData ? this.ParentTagData : '',
      };

      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.EditTag, dataObj, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.TAG]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.AddTags, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.DASHBOARD]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
  loadTag() {
    this.getTagSearchList('', false);
    this.pTagList = concat(
      of([]),
      this.internalAssigneDataSource.pipe(
        startWith(this.tagData),
        debounceTime(100),
        merge(this.focus$),
        distinctUntilChanged(),
        switchMap((term) => this.getTagSearchList(term, false)),
        map((response) => {
          return response;
        })
      )
    );
  }

  getTagSearchList(value?, allow?): Observable<any[]> {
    this.internalAssigneLoader = true;
    var searchText = value;
    let items = [];
    return this.commonService
      .callApiObservable(this.callAPIConstants.TagSearch, {
        page: 1,
        pagesize: 20,
        filter: { searchText: searchText ? searchText : '' },
      })
      .pipe(
        catchError(() => of({ items: [] })),
        map((success: any) => {
          if (success['status'] == 1) {
            this.notFoundText1 = 'No Data Found';
            items = success.data.listing;
            this.internalAssigneLoader = false;
          } else {
            this.showErrorService.popToast('error', success['message']);
          }
          return items ? items : [];
        })
      );
  }
}
