import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';

export enum ThemeMode {
  DARK,
  LIGHT,
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  // public theme$ = new BehaviorSubject<ThemeMode>(ThemeMode.LIGHT);
  private readonly THEME_KEY = 'THEME';
  private readonly DARK_THEME_VALUE = 'dark';
  private readonly LIGHT_THEME_VALUE = 'light';
  private darkThemeSelected = false;
  private horizontalALignmentSelected = false;
  private readonly HORIZONTAL_VALUE = 'horizontal';
  private readonly VERTICAL_VALUE = 'vertical';

  constructor(private storage: LocalStorageService) {}
  public switchThemeAfterLogin(darkTheme) {
    if (darkTheme) {
      this.setDarkTheme();
    } else {
      this.setLightTheme();
    }
  }

  public switchAlignmentAfterLogin(horizontal) {
    if (horizontal) {
      this.setHorizontalAlignment();
    } else {
      this.setVerticalAlignment();
    }
  }
  public switchTheme(): string {
    if (this.darkThemeSelected) {
      this.setLightTheme();
      return 'Light';
    } else {
      this.setDarkTheme();
      return 'Dark';
    }
  }

  public switchAlignment(): string {
    if (this.horizontalALignmentSelected) {
      this.setHorizontalAlignment();
      return 'Horizontal';
    } else {
      this.setVerticalAlignment();
      return 'Vertical';
    }
  }

  private setLightTheme() {
    document.body.setAttribute('data-theme', this.LIGHT_THEME_VALUE);
    this.darkThemeSelected = false;
  }

  private setDarkTheme() {
    document.body.setAttribute('data-theme', this.DARK_THEME_VALUE);
    this.darkThemeSelected = true;
  }

  private setHorizontalAlignment() {
    document.body.setAttribute('data-sidebar-pos', this.HORIZONTAL_VALUE);
    this.horizontalALignmentSelected = false;
  }

  private setVerticalAlignment() {
    document.body.setAttribute('data-sidebar-pos', this.VERTICAL_VALUE);
    this.horizontalALignmentSelected = true;
  }
}
