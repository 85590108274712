import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { Observable, Observer } from 'rxjs';
@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  base64Image: any;
  commentData: any = {};
  buttonName: any = 'Add';
  postId: any;

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      this.postId = this.bsModalRef?.content?.modalData.postId;
    });
  }

  /****************************************************************************
  @Purpose     : Add QR
  @Parameter   : form, qrData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (form.valid) {
      this.commonService
        .callApi(
          this.callAPIConstants.AddAdminComment,
          { comment: form.value.comment, postId: this.postId },
          'post'
        )
        .then((success) => {
          if (success.status == 1) {
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', success.message);
          } else {
            this.showErrorService.popToast('error', success.message);
            this.router.navigate([
              this.URLConstants.VIEWPOSTCOMMENTS + this.postId,
            ]);
          }
        });
      this.bsModalRef.hide();
    } else {
      this.submitted = true;
    }
  }
}
