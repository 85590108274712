import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss'],
})
export class AddNewsComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  NewsData: any = {};
  buttonName: any = 'Add';
  typeList = [
    { name: 'Youtube', value: 'youtube' },
    { name: 'Link', value: 'link' },
  ];

  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.bsModalRef?.content?.modalData?.type === 'edit_newslist') {
        this.buttonName = 'Update';

        this.NewsData.newsId = this.bsModalRef?.content?.modalData?.newsId;
        this.NewsData.newsTitle =
          this.bsModalRef?.content?.modalData?.newsTitle;
        this.NewsData.type = this.bsModalRef?.content?.modalData?.linkType;
        this.NewsData.description =
          this.bsModalRef?.content?.modalData?.description;
        this.NewsData.link = this.bsModalRef?.content?.modalData?.link;
        this.NewsData.isActive = this.bsModalRef?.content?.modalData?.isActive;
      }
    });
  }
  /****************************************************************************
  @Purpose     : Add/Update department
  @Parameter   : form, department
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (this.NewsData.newsId) {
      let dataObj = {
        newsId: this.NewsData.newsId,
        newsTitle: form.value.newsTitle,
        description: form.value.description,
        link: form.value.link,
        isActive: form.value.isActive,
        type: form.value.type,
      };

      if (form.valid) {
        this.commonService
          .callApi(
            this.callAPIConstants.EditNews + '/' + dataObj.newsId,
            dataObj,
            'post'
          )
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.router.navigate([this.URLConstants.NEWS]);

              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.NEWS]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    } else {
      if (form.valid) {
        this.commonService
          .callApi(this.callAPIConstants.AddNews, form.value, 'post')
          .then((success) => {
            if (success.status == 1) {
              this.broadCasterService.broadcast('listingAddUpdate', true);
              this.showErrorService.popToast('success', success.message);
            } else {
              this.showErrorService.popToast('error', success.message);
              this.router.navigate([this.URLConstants.NEWS]);
            }
          });
        this.bsModalRef.hide();
      } else {
        this.submitted = true;
      }
    }
  }
}
